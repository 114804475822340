export class ErrorWithData<T> extends Error {
    data?: T;

    /**
     * Indicates that the message can be shown on the error page, if this error reaches it
     */
    isMessagePublic: boolean;

    errorCode?: ErrorCode;

    constructor({ data, message, errorCode, isMessagePublic = false }: ErrorWithDataOptions<T>) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.data = data;
        this.isMessagePublic = isMessagePublic;
        this.errorCode = errorCode;
    }
}

export const errorCodes = ['CAMPAIGN_EXPIRED', 'MAINTENANCE_MODE'] as const;

export type ErrorCode = (typeof errorCodes)[number];

export function extractErrorCode(error: any): ErrorCode {
    return typeof error.errorCode === 'string' && errorCodes.includes(error.errorCode) ? error.errorCode : undefined;
}
export interface ErrorWithDataOptions<T> {
    data?: T;
    message?: string;
    /**
     * Indicates that the message can be shown on the error page, if this error reaches it
     */
    isMessagePublic?: boolean;
    errorCode?: ErrorCode;
}

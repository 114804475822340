import React, { FC } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useIsRTLLayout } from 'lib/hooks/useIsRTLLayout';

export const WrapHelmet: FC<React.PropsWithChildren> = ({ children }) => {
    const isRTLLayout = useIsRTLLayout();
    const htmlAttrs = isRTLLayout ? { dir: 'rtl' } : {};
    return (
        <HelmetProvider>
            <Helmet htmlAttributes={htmlAttrs}>
                <meta name="version" content={import.meta.env.REACT_APP_BUILD_NUMBER ?? 'unknown'} />
            </Helmet>
            {children}
        </HelmetProvider>
    );
};

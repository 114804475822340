import React, { FC, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { DashboardState } from 'components/types/DashboardState';
import Container from './styled/Container';
import { useTexts } from 'lib/hooks/useTexts';
import Pill from './styled/Pill';

interface BottomBarProps {
    totalNumber: number;
    completedNumber: number;
    progressPercentage: number;
    dashboardState: DashboardState;
}

const BottomBar: FC<React.PropsWithChildren<BottomBarProps>> = ({
    totalNumber,
    completedNumber,
    progressPercentage,
    dashboardState,
}) => {
    const [yourProgress, lessons] = useTexts(['yourProgress', 'lessons']);
    const theme = useTheme();

    const progressColors: Partial<Record<DashboardState, string>> = useMemo(
        () => ({
            firstVisit: theme.colors.info.color,
            laterVisit: theme.colors.warning.color,
            finalAssessment: theme.colors.success.color,
            allCompleted: theme.colors.success.color,
            failedFinalAssessment: theme.colors.success.color,
            failedAllAttempts: theme.colors.success.color,
        }),
        [theme],
    );

    const progressBgColors: Partial<Record<DashboardState, string>> = useMemo(
        () => ({
            firstVisit: theme.colors.infoBg.color,
            laterVisit: theme.colors.warningBg.color,
            finalAssessment: theme.colors.successBg.color,
            allCompleted: theme.colors.successBg.color,
            failedFinalAssessment: theme.colors.successBg.color,
            failedAllAttempts: theme.colors.successBg.color,
        }),
        [theme],
    );

    const color = progressColors[dashboardState];
    const bgColor = progressBgColors[dashboardState];

    return (
        <BottomBarDiv>
            <Container>
                <ProgressContainer>
                    <ProgressDiv>
                        <Text bold>{yourProgress}</Text>
                        <Pill $color={color} bgColor={bgColor}>
                            {progressPercentage + '%'}
                        </Pill>
                    </ProgressDiv>
                    <ProgressDiv>
                        <Pill $color={color} bgColor={bgColor}>
                            {completedNumber + '/' + totalNumber}
                        </Pill>
                        <Text>{lessons}</Text>
                    </ProgressDiv>
                </ProgressContainer>
            </Container>
        </BottomBarDiv>
    );
};

const BottomBarDiv = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: ${(props) => props.theme.background.color};
    border: 1px solid rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 500px) {
        height: 60px;
    }
`;

const ProgressContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ProgressDiv = styled.div`
    display: flex;
    align-items: center;

    @media screen and (min-width: 400px) {
        & > *:first-child {
            margin-right: 8px;
        }
    }
`;

const Text = styled.div<{ bold?: boolean }>`
    font-weight: ${(props) => (props.bold ? '600' : '300')};
`;

export default BottomBar;

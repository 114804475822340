import { createReducer } from 'typesafe-actions';
import { TelemetryActions } from '../actions/telemetry';
import Result from 'lib/types/Result';
import { TelemetryOptions } from 'lib/telemetry/types/TelemetryOptions';
interface TelemetryState {
    status: 'disabled' | 'retrying' | 'ok' | 'failed';
    options?: TelemetryOptions;
    active: boolean;
}
export const telemetryReducer = createReducer<TelemetryState, TelemetryActions>({
    status: 'ok',
    active: false,
})
    .handleAction(TelemetryActions.sendResult, (state, { payload }) =>
        Result.isSuccess(payload) ? { ...state, status: 'ok' } : { ...state, status: 'failed' },
    )
    .handleAction(TelemetryActions.telemetryEnabledStatus, (state, action) => ({
        ...state,
        status: action.payload,
    }))
    .handleAction(TelemetryActions.sendQueueStopped, (state) => ({ ...state, status: 'failed' }))
    .handleAction(TelemetryActions.retryingSend, (state) => ({ ...state, status: 'retrying' }))
    .handleAction(TelemetryActions.setOptions, (state, { payload }) => ({ ...state, options: payload }))
    .handleAction(TelemetryActions.queueStatus, (state, { payload: { active } }) => ({
        ...state,
        active,
    }));

import Res from 'lib/types/Result';
import { identity } from 'lib/functional/identity';
import { usePromiseState } from './usePromiseState';
import { useRef } from 'react';
import { useStaticRef } from './useStaticRef';
import { ValueResetTuple } from './types/ValueResetTuple';

export const usePromise = <T, U = T>(
    factory: () => Promise<T>,
    selector: (item: T) => U = identity as (item: T) => U,
    enabled = true,
): ValueResetTuple<U | undefined> => {
    const [promiseState, resetPromiseState] = usePromiseState(factory, selector, enabled);
    const resetFuncRef = useRef<() => void>();
    const resetFunc = useStaticRef(() => () => resetFuncRef.current?.());

    if (promiseState?.result) {
        const r = promiseState.result;
        if (Res.isSuccess(r)) {
            return [r.value, resetFunc];
        }
        throw r.error;
    }
    if (!resetFuncRef.current) {
        resetFuncRef.current = resetPromiseState;
    }
    return [undefined, resetFunc];
};

import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTexts } from 'lib/hooks/useTexts';
import { LangSelector } from './LangSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { isCampaignNotFound } from 'lib/api/apiTypes/CampaignResponse';
import { useParams } from 'react-router-dom';
import { ReactComponent as HomeIcon } from 'assets/icons/icon-home.svg';
import { orgIdStorageKey } from 'redux-lib/sagas/initializationSaga/userDashboardInitializationSaga';
import { isLocalStorageEnabled } from 'lib/feature-detection/isLocalStorageEnabled';

export const TopBar: FC<React.PropsWithChildren> = () => {
    const { campaignId } = useParams();
    const [topBarText, homeButtonText] = useTexts(['topBarText', 'homeButtonText']);
    const campaignResponse = useSelector((state: RootState) => state.campaign.campaignResponse);
    // why? because, for now in this case, text is hardcoded in english
    const showLangSelector = !isCampaignNotFound(campaignResponse);
    const isReturningLearner = useSelector((state: RootState) => state.campaign.isReturningLearner);

    const localStorageEnabled = isLocalStorageEnabled();
    if (!localStorageEnabled) {
        console.warn('Local storage is not available, the language popup will be shown every time');
    }

    const localStorageKey = `langPopupShown_${campaignId}`;
    React.useEffect(() => {
        if (localStorageEnabled) {
            localStorage.setItem(localStorageKey, 'true');
        }
    }, [localStorageEnabled, localStorageKey]);

    // Show popup only if it's the first time the user visits the page
    const showPopup = !localStorageEnabled || !(localStorage.getItem(localStorageKey) || isReturningLearner);

    const orgId = localStorageEnabled ? sessionStorage.getItem(orgIdStorageKey) : null;

    return (
        <TopBarContainer>
            {orgId && (
                <HomeButtonContainer>
                    <HomeButton href={`/user-dashboard/${orgId}`}>
                        <HomeIcon />
                        {homeButtonText}
                    </HomeButton>
                </HomeButtonContainer>
            )}
            {topBarText}
            {showLangSelector && <LangSelector showPopup={showPopup} popupDelay={1500} />}
        </TopBarContainer>
    );
};

export const TopBarContainer = styled.div`
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background-color: ${(props) => props.theme.topBar.color};
    font-size: 20px;
    text-align: center;

    @media screen and (max-width: 500px) {
        height: 52px;
        font-size: 18px;
        justify-content: space-between;
    }
`;

const HomeButtonContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 2.4rem;
    height: 64px;
    color: ${(props) => props.theme.colors.text.color};
    font-size: 18px;

    @media screen and (max-width: 500px) {
        position: relative;
        top: auto;
        right: auto;
        font-size: 16px;
    }
`;

const HomeButton = styled.a`
    display: flex;
    align-items: center;
    font-size: 16px;
    > svg {
        margin-right: 8px;
    }
`;

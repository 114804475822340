import React, { ReactNode } from 'react';

export class Catch extends React.Component<React.PropsWithChildren<{ onCatch: (err: any) => void }>> {
    componentDidCatch(error: unknown): void {
        this.props.onCatch(error);
    }

    render(): ReactNode {
        return this.props.children;
    }
}

import { ResettableFunction } from './ResettableFunction';

export const memoizeNullary = <T>(f: () => T): ResettableFunction<() => T> => {
    let t: T;
    let materialized = false;
    const mf = (() => {
        if (!materialized) {
            materialized = true;
            t = f();
        }
        return t;
    }) as ResettableFunction<() => T>;
    mf.reset = () => {
        materialized = false;
    };
    return mf;
};

// This type should be useful for redux actions and should be used to create action pairs:

//     getXXX: createAction('category/GET_XXX')(),
//     getXXXResult: createAction('category/GET_XXX_RESULT')<Result<THE_RESULT_TYPE>>(),

// and with these, we can indicate success or failure with a single payload by creating a payload of either by calling:
// Result.success(someValue)
// OR
// Result.error(someError)

// OR (if we use Result, not Result<T>) just

// {type:"OK"}

// When consuming the action payload, we can narrow either way:

// function({payload}){
//     if(Result.isSuccess(payload)){
//         // so something with payload.value
// 	   }else{
// 		   // do something with payload.error
// 	   }
// }

export type Result<T = undefined> = SuccessResult<T> | ErrorResult;

interface SuccessResult<T> {
    type: 'OK';
    value: T;
}
interface ErrorResult {
    type: 'Error';
    error: any;
}

export function isSuccess<T>(result: Result<T>): result is SuccessResult<T> {
    return result.type === 'OK';
}

/**
 * Create a `SuccessResult<T>` with an attached value, a possible type of the `Result<T>` union
 * @param value the value associated with this Result
 */
export function success<T>(value: T): SuccessResult<T>;
/**
 * Create a `SuccessResult` with no attached value, a possible type of the `Result<T>` union
 * when `T` is `undefined`
 */
export function success(): SuccessResult<undefined>;
export function success<T = undefined>(value?: T): SuccessResult<T> {
    return (typeof value === 'undefined' ? { type: 'OK' } : { type: 'OK', value }) as SuccessResult<T>;
}

/**
 * Creates an ErrorResult, a possible type of the Result<T> union
 * @param e an error object
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function error(e: any): ErrorResult {
    return { type: 'Error', error: e };
}

export function unwrap<T>(result: Result<T>): T {
    if (isSuccess(result)) {
        return result.value as T;
    }
    throw result.error;
}

export function map<T>(result: Result<T>, selector: (value: T) => T): Result<T> {
    return (isSuccess(result) ? success(selector(result.value as T)) : result) as Result<T>;
}

export default {
    isSuccess,
    success,
    error,
    unwrap,
    map,
};

import styled from 'styled-components/macro';
export const DestyledButton = styled.button`
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    color: inherit;
    font: inherit;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    outline: 0;
    border-radius: 0;
    text-align: inherit;
    overflow: visible;
    text-transform: none;
    & [type='button'],
    & [type='reset'],
    & [type='submit'] {
        cursor: pointer;
        -webkit-appearance: none;
        appearance: none;
        & [disabled] {
            cursor: default;
        }
        & ::-moz-focus-inner {
            border-style: none;
            padding: 0;
        }
        & :-moz-focusring {
            outline: 0;
        }
    }
    @supports (-moz-appearance: none) {
        &::-moz-focus-inner {
            border: none;
            padding: 0;
        }
        &:focus {
            outline: 0;
        }
    }
`;

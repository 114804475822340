import React, { FC } from 'react';
import { StyleSheetManager, StylisPlugin } from 'styled-components';
import { useIsRTLLayout } from 'lib/hooks/useIsRTLLayout';
import { usePromise } from 'lib/hooks/usePromise';

export const WrapStyleSheetManager: FC<React.PropsWithChildren> = ({ children }) => {
    const isRTLLayout = useIsRTLLayout();
    const [stylisPluginRTL] = usePromise(
        () => import('stylis-plugin-rtl'),
        (p) => p.default,
        isRTLLayout,
    );
    const plugins = isRTLLayout && stylisPluginRTL ? [stylisPluginRTL as unknown as StylisPlugin] : undefined;
    return <StyleSheetManager stylisPlugins={plugins}>{children}</StyleSheetManager>;
};

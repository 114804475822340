import { useInterval } from 'lib/hooks/useInterval';
import { useState } from 'react';

const intervalMs = 1000;

export function useCounter(onTick?: () => void) {
    const [startTime, setStartTime] = useState<number>(performance.now());
    const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);

    useInterval(() => {
        setElapsedSeconds(Math.round((performance.now() - startTime) / 1000));
        onTick?.();
    }, intervalMs);

    const reset = () => {
        setStartTime(performance.now());
        setElapsedSeconds(0);
    };

    return { elapsedSeconds, reset };
}

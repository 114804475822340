import { BrowserHistory, parsePath, To, Path } from 'history';

export function preserveHistoryQueryStrings(
    history: BrowserHistory,
    ...queryParametersToPreserve: string[]
): BrowserHistory {
    const paramsToPreserve = new Set(queryParametersToPreserve);
    return {
        ...history,
        push: (to: To, state?: Record<string, unknown> | null | undefined) => {
            history.push(preserveSearchParamsFromHistory(to, history, paramsToPreserve), state);
        },
        replace: (to: To, state?: Record<string, unknown> | null | undefined) => {
            history.replace(preserveSearchParamsFromHistory(to, history, paramsToPreserve), state);
        },
    };
}

function preserveSearchParamsFromHistory(
    to: To,
    history: BrowserHistory,
    paramsToPreserve: Set<string>,
): Partial<Path> {
    // To is string|PartialPath, so let's ensure that we have a PartialPath
    const toPath = typeof to === 'string' ? parsePath(to) : to;
    const originalSearchParams = new URLSearchParams(history.location.search);
    const toQueryParams = new URLSearchParams(toPath.search ?? '');
    // If there's a conflict and the preserved params appear both in the
    // requested `to` and `history.location.search`, the ones in `to` will "win"
    const queryParamEntries = [
        ...toQueryParams.entries(),
        ...Array.from(originalSearchParams.entries()).filter(([k]) => paramsToPreserve.has(k) && !toQueryParams.has(k)),
    ];
    const newQueryParams = new URLSearchParams(queryParamEntries);
    const newSearch = newQueryParams.toString();
    return { ...toPath, search: newSearch.length > 0 ? `?${newSearch}` : '' };
}

import { isOngoingCampaign } from 'lib/api/apiTypes/CampaignResponse';
import { RootState } from 'redux-lib/RootState';
import { createSelector } from 'reselect';
export interface SpinToWinCampaignInfo {
    isSpinToWinCampaign: boolean;
    isWinner: boolean;
    rewardGiven: boolean;
    rewardDescription?: string;
}

const campaignResponseSelector = (state: RootState) => state.campaign.campaignResponse;
const hasCompletedSelector = (state: RootState) => state.campaign.hasCompletedSpinToWin;

export const spinToWinCampaignSelector = createSelector(
    campaignResponseSelector,
    hasCompletedSelector,
    (campaignResponse, hasCompleted): SpinToWinCampaignInfo | null => {
        if (!isOngoingCampaign(campaignResponse)) {
            return null;
        }

        const { isSpinToWinCampaign, isWinner, rewardDescription, rewardGiven } = campaignResponse;

        return {
            isSpinToWinCampaign,
            isWinner,
            rewardDescription,
            rewardGiven: hasCompleted || rewardGiven,
        };
    },
);

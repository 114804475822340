import React, { FC } from 'react';
import { BrowserRouterProps, Router } from 'react-router-dom';
import { useHistory } from 'components/context/BrowserHistoryContext/hooks/useHistory';
export const BrowserRouter: FC<React.PropsWithChildren<React.PropsWithChildren<BrowserRouterProps>>> = ({
    children,
}) => {
    const history = useHistory();
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router location={state.location} navigator={history}>
            {children}
        </Router>
    );
};

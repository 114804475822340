import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { dedupeArray } from '../../../../lib/array/dedupeArray';

export function getModuleOrigins(dashboardResponse: DashboardResponse) {
    return dedupeArray(
        dashboardResponse.modules.flatMap((lesson) => {
            const url = new URL(lesson.url);

            if (import.meta.env.DEV) {
                // https://demo-content-devtest.outthink.io
                if (url.origin === 'https://demo-content-devtest.outthink.io') {
                    return [url.origin, 'http://demo-content-devtest.localhost:3000'];
                }
            }

            return [url.origin];
        }),
    );
}

const localStorageTestKey = '7b7160b2-e827-49ca-a4ae-edeaa3503731';
const localStorageTestValue = 'testvalue';

export const isLocalStorageEnabled = () => {
    try {
        if (!window.localStorage) {
            return false;
        }
        window.localStorage.setItem(localStorageTestKey, localStorageTestValue);
        const storedValue = window.localStorage.getItem(localStorageTestKey);
        return localStorageTestValue === storedValue;
    } catch {
        return false;
    } finally {
        try {
            window.localStorage.removeItem(localStorageTestKey);
            // eslint-disable-next-line no-empty
        } catch {}
    }
};

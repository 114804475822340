import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { CertificateResponse } from './apiTypes/CertificateResponse';
import axios from 'lib/axios';
import { policyFactory } from 'lib/axios/policyFactory';

const fakeCertificateEndpoint = '/api/certificateResponse.json';

export const fetchCertificateData = async (
    campaignId: string,
    userId: string,
): Promise<CertificateResponse | undefined> => {
    const apiUrl = import.meta.env.REACT_APP_OT_CAMPAIGN_ENDPOINT
        ? `${import.meta.env.REACT_APP_OT_CAMPAIGN_ENDPOINT}/certificate/${campaignId}/${userId}`
        : undefined;

    const apiEndpoint = apiUrl || fakeCertificateEndpoint;

    try {
        const response = await policyFactory.execute(
            (ctx) =>
                axios.get<CertificateResponse | undefined>(apiEndpoint, {
                    validateStatus: (status) => [404, 200].includes(status),
                    signal: ctx.signal,
                }),
            {
                timeoutInterval: 10000,
            },
        );
        switch (response.status) {
            case 200:
                return response.data;
            case 404:
                return undefined;
            default:
                throw new ErrorWithData({
                    data: response,
                    message: `Invalid response status code ${response.status}`,
                });
        }
    } catch (e) {
        throw new ErrorWithData({ data: e, message: 'Campaign API error' });
    }
};

import { ActionType, createAction } from 'typesafe-actions';

export interface StandardCampaignInitializationParameters {
    type: 'standardCampaignInitializationParameters';
    campaignId: string;
    userId: string;
}
export interface SCORMCampaignInitializationParameters {
    type: 'scormCampaignInitializationParameters';
    campaignId: string;
}

export interface UserDashboardInitializationParameters {
    type: 'userDashboardInitializationParameters';
    organizationId: string;
}

export type CampaignInitializationParameters =
    | StandardCampaignInitializationParameters
    | SCORMCampaignInitializationParameters;

export type InitializationParameters = CampaignInitializationParameters | UserDashboardInitializationParameters;

export const ParamsActions = {
    setParams: createAction('params/SET_PARAMS')<InitializationParameters>(),
    setUserId: createAction('params/SET_USER_ID')<string>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ParamsActions = ActionType<typeof ParamsActions>;

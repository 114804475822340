import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

export function useTenantIdentifierFromBrowserLocation() {
    const tenantIdentifierFromRoute = useParams().tenantId;
    const tenantIdentifier = useLocationURLSearchParam('tenantIdentifier') ?? tenantIdentifierFromRoute;
    return tenantIdentifier;
}
function useLocationURLSearchParam(key: string) {
    const { search } = useLocation();
    const searchParams = React.useMemo(() => new URLSearchParams(search), [search]);
    return searchParams.get(key) ?? undefined;
}

import React, { FC, FormEvent, useState } from 'react';
import { Popup } from 'components/Popup';
import styled from 'styled-components/macro';
import { ReactComponent as OtpIcon } from 'assets/icons/otp-icon.svg';
import { DestyledButton } from 'components/styled/DestyledButton';
import { useCounter } from './useCounter';
import { SecondaryButton } from 'components/styled/SecondaryCutCornerButton';
import { substituteTextsHandlebars, useTexts } from 'lib/hooks/useTexts';
import { otpRequest, otpTrade } from 'lib/api/otpRequests';
import { fetchUserDashboardData } from 'lib/api/fetchUserDashboardData';
import { useDispatch } from 'react-redux';
import { UserDashboardActions } from 'redux-lib/actions/userDashboard';
import { otpSessionStorageKey } from 'redux-lib/sagas/initializationSaga/userDashboardInitializationSaga';
import { Spinner } from 'components/Spinner';
import { Button, Buttons, Header, Title } from './styled';

export const OtpPopup: FC<
    React.PropsWithChildren<{
        close: () => void;
        email: string;
        orgId?: string;
        requestIdInitial?: string;
        expiryInitial?: string;
    }>
> = ({ close, requestIdInitial, expiryInitial, email, orgId }) => {
    if (!requestIdInitial || !expiryInitial || !email || !orgId) {
        throw new Error('missing prop');
    }
    const [code, setCode] = React.useState('');
    const [headerText, desc1Text, desc2Text, counterText, resendText, cancelText, submitText] = useTexts([
        'otpPopupHeader',
        'otpPopupDescription1',
        'otpPopupDescription2',
        'otpPopupCounter',
        'otpPopupResend',
        'cancel',
        'otpPopupSubmitButton',
    ]);
    const [requestId, setRequestId] = useState<string>(requestIdInitial);
    const [expiry, setExpiry] = useState<string>(expiryInitial);
    const dispatch = useDispatch();
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const [expired, setExpired] = useState(false);
    const { elapsedSeconds, reset } = useCounter(() => {
        if (!expired && new Date() > new Date(expiry)) {
            setExpired(true);
        }
    });

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if ((e.target as HTMLFormElement).checkValidity()) {
            setIsLoading(true);
            setError(null);
            try {
                const res = await otpTrade({ requestId, code });
                sessionStorage.setItem(otpSessionStorageKey, JSON.stringify(res));
                const dashboardData = await fetchUserDashboardData(undefined, orgId, res.sessionId);
                dispatch(UserDashboardActions.setDashboardData(dashboardData));
            } catch (e) {
                setError(e);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Popup width="450px">
            <Header>
                <OtpIcon />
                <Title>{headerText}</Title>
            </Header>
            <Description>{desc1Text}</Description>
            <Description>{desc2Text}</Description>
            <Form onSubmit={onSubmit}>
                <InputContainer>
                    <Input
                        autoFocus
                        value={code}
                        onChange={(e) => e.target.value.length <= 6 && setCode(e.target.value)}
                        pattern="\d{6,6}"
                        required
                        placeholder=" "
                    />
                    <CounterAndResend>
                        <Counter>
                            {substituteTextsHandlebars(counterText, 'counterText', {
                                elapsedSeconds: elapsedSeconds.toFixed(0),
                            })}
                        </Counter>
                        {(elapsedSeconds >= 60 || expired) && (
                            <ResendButton
                                onClick={async () => {
                                    const res = await otpRequest({ email, organizationId: orgId });
                                    setRequestId(res.requestId);
                                    setExpiry(res.expiry);
                                    setExpired(false);
                                    setError(null);
                                    reset();
                                }}
                                type="button"
                            >
                                {resendText}
                            </ResendButton>
                        )}
                    </CounterAndResend>
                </InputContainer>
                {error && (
                    <ErrorMessage>
                        {error?.message && error?.isMessagePublic ? error.message : 'Something went wrong.'}
                    </ErrorMessage>
                )}
                <Buttons>
                    <SecondaryButton type="reset" onClick={close}>
                        {cancelText}
                    </SecondaryButton>
                    <Button type="submit" disabled={isLoading}>
                        {isLoading ? <Spinner size="16px" color="white" /> : submitText}
                    </Button>
                </Buttons>
            </Form>
        </Popup>
    );
};

const Description = styled.p`
    font-size: 14px;
    :not(:last-child) {
        margin: 0 0 6px 0;
    }
`;

const Form = styled.form`
    &:has(input:invalid) button[type='submit'] {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const InputContainer = styled.div`
    max-width: 320px;
`;

const Input = styled.input`
    padding: 6px 0;
    width: 100%;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.text.color};
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.text.color} transparent;
    background-color: transparent;
    border-width: 1px;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    transition: all 250ms ease;
    :focus {
        outline: none;
    }
    :invalid:not(:placeholder-shown) {
        border-color: transparent transparent ${({ theme }) => theme.colors.danger.color} transparent;
    }
`;

const CounterAndResend = styled.div`
    margin-top: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
`;

const Counter = styled.div``;

const ResendButton = styled(DestyledButton)`
    color: ${(props) => props.theme.colors.highlight.color};
    text-align: right;
    cursor: pointer;
`;

const ErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.danger.color};
    font-size: 14px;
    margin-top: 10px;
`;

import { escapeRegExp } from 'lib/regex/escapeRegExp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';

export function useTexts(textIds: string[], substitutionIdx?: number): (string | string[])[] {
    const texts = useSelector((state: RootState) => state.texts);
    const substitutions = useSelector((state: RootState) => state.textSubstitutions);

    return useMemo(
        () =>
            textIds.map((textId) => {
                const text = texts[textId];
                if (!text) {
                    return `*Text "${textId}" is missing*`;
                }
                return substituteTextsHandlebars(text, textId, substitutions, substitutionIdx);
            }),
        [substitutionIdx, substitutions, textIds, texts],
    );
}

export const substituteTextsHandlebars = (
    text: string | string[],
    textId: string,
    substitutions: Record<string, string | string[]>,
    idx?: number,
) => {
    if (!text) {
        console.warn(`Generic text ${textId} not found`);
    }
    const regexpPattern = `{{(${Object.keys(substitutions).map(escapeRegExp).join('|')})}}`;
    const regexp = new RegExp(regexpPattern, 'g');

    const replacer = (_: string, p1: string) => {
        const substitutionItem = substitutions[p1];
        if (Array.isArray(substitutionItem)) {
            if (idx === undefined || substitutionItem[idx] === undefined) {
                console.error(`Invalid substitution item: ${textId}`);
                return `*Invalid substitution item: ${textId}*`;
            }
            return substitutionItem[idx];
        } else {
            if (substitutionItem === undefined) {
                console.error(`Invalid substitution item: ${textId}`);
                return `*Invalid substitution item: ${textId}*`;
            }
            return substitutionItem;
        }
    };

    if (Array.isArray(text)) {
        return text.map((t) => t.replace(regexp, replacer));
    }

    return text.replace(regexp, replacer);
};

import React, { FC, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { ReactComponent as HappySunIcon } from 'assets/icons/happy-sun.svg';
import { ReactComponent as FrownFaceIcon } from 'assets/icons/frown-face.svg';
import { SpinStatus } from '.';

const circleSegments = [
    // topColor, bottomColor - for segment gradient
    ['#0079E9', '#12B6FC'],
    ['#FC3012', '#FE6F5A'],
    ['#00C673', '#00E082'],
    ['#565656', '#BCBCBC'],
    ['#FF2100', '#FE6F5A'],
    ['#3D0099', '#6600FF'],
    ['#00AB96', '#00FFE0'],
    ['#454545', '#7A7A7A'],
];

const segmentRotation = 360 / 8;
const halfSegmentRotation = segmentRotation / 2;
const numberOfRotations = 10;

interface SpinToWinProps {
    spinStatus: SpinStatus;
    isWinner: boolean;
    setSpinStatus: React.Dispatch<React.SetStateAction<SpinStatus>>;
}

export const SpinToWinWheel: FC<SpinToWinProps> = ({ spinStatus, isWinner, setSpinStatus }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const el = ref.current;
        if (!el) {
            return undefined;
        }

        const handler = () => {
            setSpinStatus('finished');
        };

        el.addEventListener('transitionend', handler);

        return () => {
            el.removeEventListener('transitionend', handler);
        };
    }, [setSpinStatus]);

    const resultRotation =
        360 * numberOfRotations + (isWinner ? segmentRotation + halfSegmentRotation : halfSegmentRotation);

    return (
        <Outer>
            <Main showResult={spinStatus === 'finished'}>
                <WheelContainer>
                    <WheelEdge>
                        <WheelTicker />
                        <StyledSpinToWinWheel
                            hasSpun={spinStatus === 'started' || spinStatus === 'finished'}
                            resultRotation={resultRotation}
                            ref={ref}
                        >
                            <div>
                                {circleSegments.map((segment, i) => (
                                    <Segment
                                        key={i}
                                        rotation={segmentRotation * i}
                                        topColor={segment[0]}
                                        bottomColor={segment[1]}
                                    />
                                ))}
                            </div>
                            <div>
                                {circleSegments.map((_, i) => (
                                    <SegmentLabelPosition key={i} rotation={segmentRotation * i}>
                                        <SegmentLabel>
                                            {i % 2 ? (
                                                <FrownFaceIcon />
                                            ) : (
                                                <>
                                                    <HappySunIcon />
                                                    <HSBackground />
                                                </>
                                            )}
                                        </SegmentLabel>
                                    </SegmentLabelPosition>
                                ))}
                            </div>
                            <WheelCenter />
                        </StyledSpinToWinWheel>
                    </WheelEdge>
                </WheelContainer>
            </Main>
        </Outer>
    );
};

interface SegmentRotationProps {
    rotation: number;
}

interface SegmentProps extends SegmentRotationProps {
    topColor: string;
    bottomColor: string;
}

const Outer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 30px;
    padding: 40px;
    box-sizing: border-box;
    transition: all 300ms ease;
`;
const Main = styled.div<{ showResult: boolean }>`
    transition: max-height 0.3s linear;
`;

const WheelContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const WheelEdge = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41vmin;
    width: 41vmin;
    background:
        linear-gradient(225deg, #000604 16%, #303030 44.73%, #000604 87.83%) padding-box,
        linear-gradient(225deg, #ffffff 16%, #7a7a7a 87.83%) border-box;
    padding: 1vmin;
    border-radius: 50%;
    border: 0.5vmin solid transparent;

    @media screen and (max-width: 635px) {
        height: 250px;
        width: 250px;
    }
`;

const WheelCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4vmin;
    height: 4vmin;
    background:
        linear-gradient(274.78deg, #303030 32.63%, #000604 127.73%) padding-box,
        linear-gradient(180.02deg, #f2f701 4.18%, #fe6f5a 33.11%, #fa2cd7 59.9%, #5b01e3 85.62%, #01ddc2 111.34%)
            border-box;
    border-radius: 50%;
    border: 0.35vmin solid black;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 635px) {
        height: 21px;
        width: 21px;
    }
`;

const WheelTicker = styled.div`
    position: absolute;
    top: -0.25vmin;
    left: calc(50% - 1.25vmin);
    width: 2.5vmin;
    height: 4vmin;
    background: white;
    z-index: 1;
    margin-bottom: 1vmin;

    &:after {
        content: '';
        position: absolute;
        bottom: -0.85vmin;

        border-style: solid;
        border-width: 1vmin 1.3vmin 0 1.3vmin;
        border-color: #ffffff transparent transparent transparent;
    }

    @media screen and (max-width: 635px) {
        height: 20px;
        width: 13px;

        &:after {
            bottom: -6px;
            border-width: 6px 6px 0px 7px;
        }
    }
`;

const StyledSpinToWinWheel = styled.div<{
    hasSpun: boolean;
    resultRotation: number;
}>`
    overflow: hidden;
    position: relative;
    margin: 1em auto;
    padding: 0;
    width: 35vmin;
    height: 35vmin;
    border-radius: 50%;
    list-style: none;
    transition: rotate 5s ease-out;
    rotate: ${(props) =>
        props.hasSpun
            ? css`
                  ${props.resultRotation}deg
              `
            : css`0deg`};
    background:
        linear-gradient(135deg, #878787 16.82%, #ffffff 82.25%) padding-box,
        linear-gradient(135deg, #878787 16.82%, #ffffff 82.25%) border-box;

    border: 0.5vmin solid transparent;
    transform-origin: center center;

    @media screen and (max-width: 635px) {
        height: 220px;
        width: 220px;
    }
`;

const Segment = styled.div<SegmentProps>`
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 50%;

    transform-origin: 0% 100%;
    transform: rotate(${(props) => props.rotation}deg) skewY(-45deg);

    background: radial-gradient(
        ellipse 125% 125% at right top,
        ${(props) => props.topColor} 25%,
        ${(props) => props.bottomColor} 100%
    );
`;

const SegmentLabelPosition = styled.div<SegmentRotationProps>`
    position: absolute;
    top: 0%;
    left: 50%;
    height: 50%;
    width: 50%;

    transform-origin: bottom left;
    transform: rotate(${(props) => props.rotation + 22.5}deg);
`;

const SegmentLabel = styled.div`
    position: absolute;
    top: calc(50% - 2.7vmin);
    left: 0%;
    font-size: max(1rem, 2vmin);
    color: white;
    height: 33.333%;
    width: 33.333%;
    text-shadow: 0px 1px 4px 0px #00000040;
    z-index: 1;

    transform-origin: left center;
    transform: rotate(-90deg);

    & > svg {
        transform: rotate(90deg);
        height: 100%;
        width: 100%;
    }
`;

const HSBackground = styled.div`
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
    background: radial-gradient(50% 50% at 50% 50%, #faff00cc 0%, rgba(250, 255, 0, 0) 100%);
`;

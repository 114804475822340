import { getDefaultThemeConfig, ThemeConfig } from 'outthink-frontend-training-theme';
import { getBaseTheme } from './getBaseTheme';
import { ThemeSettings } from './ThemeSettings';

export function getThemeSettings(
    savedThemeConfigDashboard: ThemeConfig | undefined,
    savedThemeConfigTraining: ThemeConfig | undefined,
): ThemeSettings {
    const themeConfigDashboard = savedThemeConfigDashboard ?? getDefaultThemeConfig('dashboard');
    const themeConfigTraining = savedThemeConfigTraining ?? getDefaultThemeConfig('training');
    const theme = getBaseTheme(themeConfigDashboard, themeConfigTraining);
    return {
        theme,
        config: themeConfigDashboard,
    };
}

import { useState, useEffect, useRef } from 'react';
import Res from 'lib/types/Result';
import { identity } from 'lib/functional/identity';
import { useStaticRef } from '../useStaticRef';
import { ValueResetTuple } from '../types/ValueResetTuple';
import { PromiseState } from './PromiseState';
import { memoizeNullary } from 'lib/memoization/memoizeNullary';

export const usePromiseState = <T, U = T>(
    factory: () => Promise<T>,
    selector: (item: T) => U = identity as (item: T) => U,
    enabled = true,
): ValueResetTuple<PromiseState<T, U> | undefined> => {
    const [promiseState, setPromiseState] = useState<PromiseState<T, U>>();
    const resetFuncRef = useRef<() => void>();
    const resetFunc = useStaticRef(() => () => resetFuncRef.current?.());
    const fac = useStaticRef(() => memoizeNullary(factory));
    useEffect(() => {
        let mounted = true;
        let counter = 0;
        if (enabled) {
            if (resetFuncRef.current == null) {
                // closure madness!
                resetFuncRef.current = () => {
                    fac.reset();
                    initialize(++counter);
                };
                const completion = (promise: Promise<T>, value: T, currentCount: number) => {
                    return (
                        mounted &&
                        currentCount === counter &&
                        setPromiseState({
                            result: Res.success(selector(value)),
                            promise,
                        })
                    );
                };
                const error = (promise: Promise<T>, e: any, currentCount: number) => {
                    return (
                        mounted &&
                        currentCount === counter &&
                        setPromiseState({
                            result: Res.error(e),
                            promise,
                        })
                    );
                };
                const pending = (currentCount: number) => {
                    const promise = fac();
                    if (mounted && currentCount === counter) {
                        setPromiseState({
                            promise,
                        });
                    }
                    return mounted ? promise : undefined;
                };
                const initialize = (currentCount: number) => {
                    const promise = pending(currentCount);
                    promise
                        ?.then((value) => {
                            completion(promise, value, currentCount);
                        })
                        .catch((e) => error(promise, e, currentCount));
                };
                resetFunc();
            }
        }
        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [enabled]);
    return [promiseState, resetFunc];
};

// export type CampaignResponse = NotExpiredCampaign | ExpiredCampaign | NotInCampaign;

// interface NotExpiredCampaign {
//     campaignExpired: false;
//     currentAssignment: {
//         assignmentId: string;
//         assignmentType: 'lesson' | 'initialAssessment' | 'finalAssessment';
//         moduleId: string;
//     };
//     nextAssignment?: {
//         assignmentId: string;
//         assignmentType: 'lesson' | 'initialAssessment' | 'finalAssessment';
//         moduleId: string;
//     };
//     completedModules: string[];
//     dashboardUrl: string;
//     contentLanguage: string;
//     failedFinalAssessment?: boolean;
//     failedAllAttempts?: boolean;
// }

// interface ExpiredCampaign {
//     campaignExpired: true;
// }

interface NotInCampaign {
    notInCampaign: true;
    contentLanguage: string;
}

interface CampaignResponseNotFound {
    campaignResponseNotFound: true;
    contentLanguage: string;
}

type MainCampaignResponse = {
    campaignExpired: false;
    currentAssignment: {
        assignmentId: string;
        assignmentType: 'lesson' | 'initialAssessment' | 'finalAssessment';
        moduleId: string;
    };
    nextAssignment?: {
        assignmentId: string;
        assignmentType: 'lesson' | 'initialAssessment' | 'finalAssessment';
        moduleId: string;
    };

    completedModules: string[];
    dashboardUrl: string;
    contentLanguage: string;
    certificateEnabled?: boolean;
    organizationId?: string;
    isSpinToWinCampaign: boolean;
    isWinner: boolean;
    rewardGiven: boolean;
    rewardDescription?: string;
};

interface ExpiredCampaign {
    campaignExpired: true;
}

interface TrainingExpiredCampaign {
    daysLeft: 0;
    endDate: string;
    contentLanguage?: string;
}

type PassRateRequiredCampaign = {
    failedFinalAssessment: boolean;
    failedAllAttempts: boolean;
    score: number;
    attempts: number;
    retriesEnabled: boolean;
    highestScore: number;
    remainingAttempts: number;
    passingScore: number;
    passRateRequired: true;
} & MainCampaignResponse;

type OngoingCampaign = PassRateRequiredCampaign | PassRateNotRequiredCampaign;

type PassRateNotRequiredCampaign = {
    passRateRequired: false;
} & MainCampaignResponse;

export type CampaignResponse =
    | OngoingCampaign
    | ExpiredCampaign
    | NotInCampaign
    | CampaignResponseNotFound
    | TrainingExpiredCampaign;

export function isOngoingCampaign(c?: CampaignResponse): c is OngoingCampaign {
    return (
        !!c && !isExpiredCampaign(c) && !isNotInCampaign(c) && !isCampaignNotFound(c) && !isTrainingExpiredCampaign(c)
    );
}

export function isExpiredCampaign(c?: CampaignResponse): c is ExpiredCampaign {
    return !!c && 'campaignExpired' in c && c.campaignExpired;
}

export function isNotInCampaign(c?: CampaignResponse): c is NotInCampaign {
    return !!c && 'notInCampaign' in c && c.notInCampaign;
}

export function isCampaignNotFound(c?: CampaignResponse): c is CampaignResponseNotFound {
    return !!c && 'campaignResponseNotFound' in c && c.campaignResponseNotFound;
}

export function isPassRateRequiredCampaign(c?: CampaignResponse): c is PassRateRequiredCampaign {
    return isOngoingCampaign(c) && c.passRateRequired;
}

export function isTrainingExpiredCampaign(c?: CampaignResponse): c is TrainingExpiredCampaign {
    return !!c && 'daysLeft' in c && 'endDate' in c && c.daysLeft <= 0;
}

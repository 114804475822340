import styles from './expander.module.css';
import { ReactComponent as DownArrow } from './images/downarrow.svg';
import { FC, useState } from 'react';
import { useMemo } from 'react';

export const Expander: FC<React.PropsWithChildren<{ title: string | JSX.Element }>> = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false);
    const downArrowClassName = useMemo(
        () => [styles['down-arrow'], ...(expanded ? [styles['down-arrow-rotated']] : [])].join(' '),
        [expanded],
    );

    return (
        <>
            <div className={styles['open-expander']} onClick={() => setExpanded(!expanded)}>
                {title} <DownArrow className={downArrowClassName} />
            </div>
            {expanded && children}
        </>
    );
};

export type PromiseResolver<T> = {
    resolve: (v: T) => void;
    reject: (v?: any) => void;
    promise: Promise<T>;
};

export const createPromiseResolver = <T>(abortSignal?: AbortSignal): PromiseResolver<T> => {
    let resolve: (v: T) => void = null!;
    let reject: (v?: any) => void = null!;
    let isResolved = false;
    const abortSignalHandler = abortSignal
        ? () => {
              if (!isResolved) {
                  reject(Error('PromiseResolver was aborted'));
              }
              if (!abortSignalHandler) {
                  throw Error('unexpected');
              }
              abortSignal.removeEventListener('abort', abortSignalHandler);
          }
        : undefined;

    const p = new Promise<T>((res, rej) => {
        resolve = res;
        reject = rej;
    });
    if (abortSignal?.aborted) {
        reject(Error('PromiseResolver was aborted'));
    }
    if (abortSignalHandler && abortSignal) {
        abortSignal.addEventListener('abort', abortSignalHandler);
    }
    const wrappedResolve = (...args: Parameters<typeof resolve>): ReturnType<typeof resolve> => {
        if (abortSignalHandler && abortSignal) {
            abortSignal.removeEventListener('abort', abortSignalHandler);
        }
        isResolved = true;
        return resolve(...args);
    };
    const wrappedReject = (...args: Parameters<typeof reject>): ReturnType<typeof reject> => {
        if (abortSignalHandler && abortSignal) {
            abortSignal.removeEventListener('abort', abortSignalHandler);
        }
        isResolved = true;
        return reject(...args);
    };
    return { resolve: wrappedResolve, reject: wrappedReject, promise: p };
};

import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { Result } from 'lib/types/Result';
import { ActionType, createAction } from 'typesafe-actions';

export const DashboardDataActions = {
    fetchResult: createAction('dashboardData/FETCH_RESULT')<Result<DashboardResponse>>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type DashboardDataActions = ActionType<typeof DashboardDataActions>;

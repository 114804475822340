import { appInsights } from './appInsights';
import { v4 as uuid } from 'uuid';
import { ICustomProperties, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';

export interface TrackExceptionOptions {
    errorCode: string;
    exception: IExceptionTelemetry;
    customProperties?: ICustomProperties;
    addTrackingDetailsToException: boolean;
}

export interface TrackingDetails {
    trackingID: string;
    errorCode: string;
}

export const isErrWithTrackingDetails = (e: any): e is { trackingDetails: TrackingDetails } =>
    'trackingDetails' in e &&
    typeof e.trackingDetails === 'object' &&
    'trackingID' in e.trackingDetails &&
    'errorCode' in e.trackingDetails &&
    typeof e.trackingDetails.trackingID === 'string' &&
    typeof e.trackingDetails.errorCode === 'string';

export const trackException = ({
    errorCode,
    exception,
    customProperties,
    addTrackingDetailsToException,
}: TrackExceptionOptions) => {
    if (!appInsights) {
        console.warn('No instrumentation key. Exception will not be tracked');
        return;
    }
    const trackingID = uuid();
    const e: IExceptionTelemetry = {
        ...exception,
        properties: { ...exception.properties, errorCode, trackingID },
    };
    const custProps: ICustomProperties = {
        ...customProperties,
        rawError: prepareErrorForSerialization(exception.exception),
    };
    appInsights.trackException(e, custProps);
    appInsights.flush();
    if (addTrackingDetailsToException) {
        const e = exception.exception as any;
        if (e) {
            const trackingDetails: TrackingDetails = { trackingID, errorCode };
            e.trackingDetails = trackingDetails;
        }
    }
};

import { createAction, ActionType } from 'typesafe-actions';
import { TelemetryEvent } from 'lib/telemetry/types/TelemetryEvent';
import { Result } from 'lib/types/Result';
import { RetryInfo } from 'lib/redux-saga/effects/types/RetryInfo';
import { TelemetryOptions } from 'lib/telemetry/types/TelemetryOptions';

export const TelemetryActions = {
    send: createAction('telemetry/SEND')<TelemetryEvent>(),
    retryingSend: createAction('telemetry/RETRYING_SEND')<RetryInfo>(),
    sendResult: createAction('telemetry/SEND_RESULT')<Result>(),
    sendQueueStopped: createAction('telemetry/SEND_QUEUE_STOPPED')(),
    telemetryEnabledStatus: createAction('telemetry/TELEMETRY_ENABLED_STATUS')<
        'disabled' | 'retrying' | 'ok' | 'failed'
    >(),
    setOptions: createAction('telemetry/SET_OPTIONS')<TelemetryOptions>(),
    queueStatus: createAction('telemetry/QUEUE_STATUS')<{ active: boolean }>(),
    startTelemetry: createAction('telemetry/START_TELEMETRY')(),
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TelemetryActions = ActionType<typeof TelemetryActions>;

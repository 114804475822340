import React, { FC, FormEvent, useMemo, useState } from 'react';
import { DestyledButton } from 'components/styled/DestyledButton';
import styled from 'styled-components/macro';
import { fetchUserDashboardData } from 'lib/api/fetchUserDashboardData';
import { useDispatch, useSelector } from 'react-redux';
import { UserDashboardActions } from 'redux-lib/actions/userDashboard';
import { Spinner } from 'components/Spinner';
import { useTexts } from 'lib/hooks/useTexts';
import {
    orgIdStorageKey,
    userDataStorageKey,
} from 'redux-lib/sagas/initializationSaga/userDashboardInitializationSaga';
import { RootState } from 'redux-lib/RootState';
import { useParams } from 'react-router';
import { isLocalStorageEnabled } from 'lib/feature-detection/isLocalStorageEnabled';
import { UserDashboardConfig } from 'lib/api/apiTypes/UserDashboardConfig';
import { OtpPopup } from './components/OtpPopup';
import { otpRequest } from 'lib/api/otpRequests';
import { ConfirmationPopup } from './components/ConfirmationPopup';
import { SignInOption } from 'lib/api/apiTypes/SignInOption';
import { replacePlaceholders } from 'lib/util/replacePlaceholders';

const defaultLogoUrl = 'https://client-resources.outthink.io/clients/demo/logo.png';

export const Login: FC<React.PropsWithChildren> = () => {
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState<any>(null);
    const dispatch = useDispatch();
    const [
        titleText,
        buttonText,
        loginMethodEmailButtonText,
        loginMethodCustomButtonText,
        loginMethodSeparatorText,
        descriptionText,
        [emailInfoLabel],
    ] = useTexts([
        'userDashboardLoginTitle',
        'userDashboardLoginButton',
        'userDashboardLoginMethodEmailButton',
        'userDashboardLoginMethodCustomIdButton',
        'userDashboardLoginSeparator',
        'userDashboardLoginDescription',
        'userDashboardLoginEmailInfoLabel',
    ]);
    const logoUrl = useSelector((state: RootState) => state.initialization.themeConfig?.primaryLogo);
    const { orgId } = useParams();
    if (!orgId) {
        throw new Error('orgId param is not defined');
    }
    const dashboardConfig = useSelector((state: RootState) => state.userDashboard?.dashboardConfig);
    const isCustomIdEnabled = !!dashboardConfig?.customIdName?.length;
    const [signInOption, setSignInOption] = useState<SignInOption>(
        isCustomIdEnabled && dashboardConfig?.signInOption ? dashboardConfig?.signInOption : 'email, otp',
    );
    const theme = dashboardConfig?.theme || 'splitScreen';

    const [showOtpPopup, setShowOtpPopup] = useState<{ requestId: string; expiry: string } | undefined>();
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<{ onConfirm: () => void } | undefined>();

    const localStorageEnabled = isLocalStorageEnabled();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!input1) {
            return;
        }
        setLoading(true);
        setError(null);

        if (signInOption === 'customId') {
            try {
                const requestBody = { email: '', customId: input1 };
                const res = await fetchUserDashboardData(requestBody, orgId);
                setLoading(false);
                setShowConfirmationPopup({
                    onConfirm: () => {
                        if (localStorageEnabled) {
                            sessionStorage.setItem(userDataStorageKey, JSON.stringify(requestBody));
                            sessionStorage.setItem(orgIdStorageKey, orgId);
                        } else {
                            console.warn('Local storage is not available.');
                        }
                        dispatch(UserDashboardActions.setDashboardData(res));
                    },
                });
            } catch (e: any) {
                setError(e);
                setLoading(false);
            }
        } else {
            try {
                const res = await otpRequest({ email: input1, organizationId: orgId });
                setShowOtpPopup(res);
            } catch (e: any) {
                setError(e);
            }
            setLoading(false);
        }
    };

    const [input1Texts, input2Texts] = useMemo(
        () => getInputTexts(dashboardConfig, signInOption, emailInfoLabel),
        [dashboardConfig, emailInfoLabel, signInOption],
    );

    const toggleSignInOption = () => {
        setInput1('');
        setInput2('');
        if (signInOption === 'email, otp') {
            setSignInOption('customId');
        } else if (signInOption === 'customId') {
            setSignInOption('email, otp');
        }
    };

    const formattedCustomIdButtonText = replacePlaceholders(loginMethodCustomButtonText, {
        CustomIdName: dashboardConfig?.customIdName ?? 'CustomIdName',
    });
    const methodChangeText = signInOption === 'email, otp' ? formattedCustomIdButtonText : loginMethodEmailButtonText;

    const defaultBackgroundUrl =
        'https://client-resources.outthink.io/modules/0e8c2a5f-6375-4524-bfa0-ab0a0a681d50.png';

    return (
        <Container>
            <LeftSide variant={theme}>
                <LeftSideInnerContainer>
                    <LogoContainer>
                        <img src={logoUrl ?? defaultLogoUrl} alt="logo" />
                    </LogoContainer>
                    <SignInFormContainer>
                        <SignInForm onSubmit={onSubmit}>
                            <Heading>{titleText}</Heading>
                            <InputContainer>
                                <Input
                                    type="text"
                                    placeholder={input1Texts.label}
                                    value={input1}
                                    onChange={(e) => setInput1(e.target.value)}
                                />
                                {input1Texts.tooltip && <SubText>{input1Texts.tooltip}</SubText>}
                            </InputContainer>
                            {input2Texts && (
                                <InputContainer>
                                    <Input
                                        type="text"
                                        placeholder={input2Texts.label}
                                        value={input2}
                                        onChange={(e) => setInput2(e.target.value)}
                                    />
                                    {input2Texts.tooltip && <SubText>{input2Texts.tooltip}</SubText>}
                                </InputContainer>
                            )}
                            <ErrorMessage>
                                {error &&
                                    (error?.message && error?.isMessagePublic
                                        ? error.message
                                        : 'Something went wrong.')}
                            </ErrorMessage>
                            <Button type="submit" disabled={loading || !input1}>
                                {loading ? <Spinner size="20px" color="white" /> : buttonText}
                            </Button>
                        </SignInForm>
                        {isCustomIdEnabled && (
                            <>
                                <HR data-content={loginMethodSeparatorText} />
                                <Button secondary disabled={loading} onClick={toggleSignInOption}>
                                    {methodChangeText}
                                </Button>
                            </>
                        )}
                    </SignInFormContainer>
                    <VersionInfo></VersionInfo>
                    {theme === 'fullBackground' && (
                        <DescriptionFullBackground>{descriptionText}</DescriptionFullBackground>
                    )}
                </LeftSideInnerContainer>
            </LeftSide>
            <RightSide backgroundUrl={dashboardConfig?.imageUrl || defaultBackgroundUrl}>
                {theme === 'splitScreen' && <DescriptionSplitScreen>{descriptionText}</DescriptionSplitScreen>}
            </RightSide>
            {showOtpPopup && (
                <OtpPopup
                    close={() => setShowOtpPopup(undefined)}
                    email={input1}
                    requestIdInitial={showOtpPopup.requestId}
                    expiryInitial={showOtpPopup.expiry}
                    orgId={orgId}
                />
            )}
            {showConfirmationPopup && (
                <ConfirmationPopup
                    code={input1}
                    customIdName={dashboardConfig?.customIdName ?? 'CustomID'}
                    onConfirm={showConfirmationPopup.onConfirm}
                    onCancel={() => setShowConfirmationPopup(undefined)}
                />
            )}
        </Container>
    );
};

function getInputTexts(
    dashboardConfig: UserDashboardConfig | undefined,
    signInOption: SignInOption,
    emailInfoLabel: string,
): { label: string; tooltip?: string | null }[] {
    if (!dashboardConfig) {
        throw new Error('No dashboardConfig.');
    }
    const customIdName = dashboardConfig.customIdName ? dashboardConfig.customIdName : 'Custom ID';
    const customIdTooltip = dashboardConfig.customIdTooltip
        ? dashboardConfig.customIdTooltip
        : 'Please enter the required ID or code in order to sign.';
    if (signInOption === 'email, otp') {
        return [{ label: 'Email', tooltip: emailInfoLabel }];
    }
    if (signInOption === 'customId') {
        return [{ label: customIdName, tooltip: customIdTooltip }];
    }
    throw new Error('Invalid sign in option.');
}

const Container = styled.div`
    display: flex;
    height: 100%;
    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const VersionInfo = styled.div``;

const LeftSide = styled.div<{
    variant: 'splitScreen' | 'fullBackground';
}>`
    height: 100%;

    ${(props) =>
        props.variant === 'fullBackground'
            ? 'position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%); height: auto; width: 640px; border-radius: 4px;'
            : 'width: 50vw;'}

    @media (max-width: 900px) {
        min-height: 100%;

        ${(props) => (props.variant === 'fullBackground' ? 'min-width: 100%;' : 'width: auto;')}
    }
`;

const LeftSideInnerContainer = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 64px 0;
    position: relative;
`;

const SignInFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    max-width: 460px;
    width: 100%;

    @media (max-width: 1200px) {
        padding: 0 64px;
    }
`;

const SignInForm = styled.form`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    min-width: 0;
`;

const InputContainer = styled.div`
    width: 100%;
    max-width: 100%;
    :not(:first-child) {
        margin-top: 20px;
    }
`;

const SubText = styled.div`
    margin-top: 16px;
    font-size: 18px;
    opacity: 0.9;
    font-weight: 300;
    color: #596180;
`;

const RightSide = styled.div<{
    backgroundUrl: string;
}>`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: end;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 40vh),
        ${(props) => (props.backgroundUrl ? `url(${props.backgroundUrl})` : 'none')};
    padding: calc((100vh - 250px) * 0.4) 20px 20px;
    min-width: 0;
`;

const Heading = styled.h2`
    margin: 0 0 50px 0;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    ${(props) => props.theme.fontFamilyCSS.heading}
`;

const Input = styled.input`
    width: 100%;
    padding: 24px 24px;
    background: rgba(245, 245, 245, 0.8);
    border: none;
    color: #596180;
    border-radius: 8px;
    font-size: 18px;
    font-family: inherit;
    outline: 1px solid transparent;
    transition: outline 0.3s;
    :hover {
        outline: 1px solid rgba(13, 66, 107, 0.2);
    }
    :focus {
        outline: 1px solid rgba(13, 66, 107, 0.4);
    }
`;

const Button = styled(DestyledButton)<{
    secondary?: boolean;
}>`
    width: 100%;
    min-height: 53px;
    padding: 20px 20px;
    border-radius: 2px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s;
    :disabled {
        opacity: 0.5;
    }

    background: ${(props) => (props.secondary ? 'transparent' : '#0d436b')};
    color: ${(props) => (props.secondary ? '#000000' : '#ffffff')};
    border-radius: 12px;
    border: ${(props) => (props.secondary ? '2px solid #FFB200' : 'none')};
`;

const ErrorMessage = styled.div`
    min-height: 48px;
    height: auto;
    width: 100%;
    text-align: left;
    line-height: 1.3;
    color: ${(props) => props.theme.colors.danger.color};
    display: flex;
    align-items: flex-start;
    padding-top: 8px;
    margin-bottom: 8px;
`;

const LogoContainer = styled.div`
    width: 192px;
    max-width: 100%;
    display: flex;
    justify-content: center;
`;

const HR = styled.hr`
    width: 100%;
    max-width: 100%;
    border: 0;
    border-bottom: 1px solid #d4d5d6;
    position: relative;
    text-align: center;
    overflow: visible;

    &::after {
        content: attr(data-content);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        padding: 0 16px;
        line-height: 1.5em;
        color: #d4d5d6;
        background-color: #ffffff;
    }
`;

const DescriptionSplitScreen = styled.p`
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    max-width: 100%;
    color: #ffffff;
    text-align: left;
    line-height: 32px;
    padding: 32px 64px;
`;

const DescriptionFullBackground = styled.p`
    font-size: 24px;
    font-weight: 300;
    color: #ffffff;
    text-align: left;
    line-height: 32px;
    padding: 32px 64px;
    text-align: center;
    opacity: 0.8;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
`;

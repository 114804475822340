import { css, FlattenSimpleInterpolation } from 'styled-components/macro';

type Corner = 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight';

export const cutCorner = (cutSize: string, corner: Corner = 'topRight'): FlattenSimpleInterpolation =>
    cssMap[corner](cutSize);

const cutCornerTopRight = (cutSize: string): FlattenSimpleInterpolation => css`
    clip-path: polygon(0% 0%, calc(100% - ${cutSize}) 0%, 100% ${cutSize}, 100% 100%, 0% 100%);
`;

const cutCornerTopLeft = (cutSize: string): FlattenSimpleInterpolation => css`
    clip-path: polygon(${cutSize} 0%, 100% 0%, 100% 100%, 0% 100%, 0% ${cutSize});
`;

const cutCornerBottomRight = (cutSize: string): FlattenSimpleInterpolation => css`
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - ${cutSize}), calc(100% - ${cutSize}) 100%, 100% 100%, 0% 100%);
`;

const cutCornerBottomLeft = (cutSize: string): FlattenSimpleInterpolation => css`
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, ${cutSize} 100%, 0% calc(100% - ${cutSize}));
`;

const cssMap: Record<Corner, (cutSize: string) => FlattenSimpleInterpolation> = {
    topRight: cutCornerTopRight,
    topLeft: cutCornerTopLeft,
    bottomRight: cutCornerBottomRight,
    bottomLeft: cutCornerBottomLeft,
};

import styles from './error-styles.module.css';
import im from './images/im1.jpg';

export const DashboardNotEnabled: React.FC<React.PropsWithChildren> = () => (
    <div className={styles.main}>
        <h1 className={styles.heading}>Training dashboard not enabled</h1>
        <p className={styles.body}>Training dashboard is not enabled for this organization.</p>
        <img className={styles.image2} src={im} />
    </div>
);

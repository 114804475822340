import { ShowError } from 'components/error/ShowError';
import { BrowserHistory } from 'history';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { Router } from 'router/Router';
import { ThemeProvider } from 'styled-components';
import { WrapHelmet } from 'WrapHelmet';
import { WrapStyleSheetManager } from 'WrapStyleSheetManager';
import { loadFontFaces } from 'lib/fonts/loadFontFaces';
import { fontFaces as bundledFontFaces } from 'fontFaces';
import { useThemeSettings } from 'theme/useThemeSettings';
import { loadRemoteFonts, ThemeConfig } from 'outthink-frontend-training-theme';
import { BrowserHistoryProvider } from 'components/context/BrowserHistoryContext/BrowserHistoryProvider';
import { NetworkStatus } from 'components/NetworkStatus';

const App: FC<React.PropsWithChildren<{ history: BrowserHistory }>> = ({ history }) => {
    const error = useSelector(
        (state: RootState) => state.campaign.error || state.texts.error || state.initialization.error,
    );
    const themeSettings = useThemeSettings();
    useFonts(themeSettings.config);

    return (
        <>
            <WrapHelmet>
                <ThemeProvider theme={themeSettings.theme}>
                    <WrapStyleSheetManager>
                        <BrowserHistoryProvider history={history}>
                            <NetworkStatus />
                            {error ? <ShowError error={error} /> : <Router />}
                        </BrowserHistoryProvider>
                    </WrapStyleSheetManager>
                </ThemeProvider>
            </WrapHelmet>
        </>
    );
};

export default App;

function useFonts(themeConfig: ThemeConfig | undefined) {
    React.useEffect(() => {
        if (!themeConfig) {
            return;
        }
        const { skipDefaultBundledTypeFace, remoteFonts } = themeConfig;
        const fontsToInclude = [...(skipDefaultBundledTypeFace ? [] : ['MatterSQ', 'Space Grotesk', 'Poppins'])];

        const filteredFonts = bundledFontFaces.filter((f) => fontsToInclude.includes(f.family));
        loadFontFaces(filteredFonts, true);

        loadRemoteFonts(remoteFonts);
    }, [themeConfig]);
}

import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { fetchCertificateData } from 'lib/api/fetchCertificateData';
import { CertificateResponse } from 'lib/api/apiTypes/CertificateResponse';

export function* getCertificateData(campaignId: string, userId: string): SagaIterator {
    const certificateData: CertificateResponse = yield call(async () => {
        const apiResponse = await fetchCertificateData(campaignId, userId);
        return apiResponse;
    });

    return certificateData;
}

import React from 'react';
import styles from './error-styles.module.css';
import im from './images/im2_medium.jpg';

export interface MaintenanceModeProps {
    endTime?: string;
}

export const MaintenanceMode: React.FC<React.PropsWithChildren<MaintenanceModeProps>> = ({ endTime }) => {
    const endTimePretty = React.useMemo(() => {
        try {
            const endTimeDate = endTime ? new Date(endTime) : undefined;
            return endTimeDate
                ? {
                      endDate: endTimeDate.toLocaleString(undefined, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                      }),
                      endTime: endTimeDate.toLocaleString(undefined, {
                          hour12: false,
                          hour: '2-digit',
                          minute: '2-digit',
                      }),
                  }
                : undefined;
        } catch {
            return undefined;
        }
    }, [endTime]);
    return (
        <div className={styles.main}>
            <h1 className={styles.heading}>This training course is in maintenance mode</h1>
            <p className={styles.body}>
                We apologise for the inconvenience.
                {endTimePretty ? (
                    <>
                        {' '}
                        Maintenance will end at {endTimePretty.endTime} on {endTimePretty.endDate}. Please try
                        completing your training after this.
                    </>
                ) : (
                    <> Please try again in a few minutes.</>
                )}
            </p>
            <img className={styles.image} src={im} />
        </div>
    );
};

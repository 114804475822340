import { useMemo } from 'react';
import { useTenantIdentifierFromBrowserLocation } from 'pages/useTenantIdentifierFromBrowserLocation';
import { UserDashboardLoginResponse } from 'lib/api/apiTypes/UserDashboardLoginResponse';
import { UserDashboardCampaign } from 'lib/api/apiTypes/UserDashboardCampaign';

export function useEnhancedCampaigns({
    campaigns,
    dashboardData,
}: UseEnhancedCampaignsOpts): EnhancedUserDashboardCampaign[] {
    const tenantIdentifier = useTenantIdentifierFromBrowserLocation();
    return useMemo(
        () =>
            campaigns.map((campaign) => ({
                ...campaign,
                dashboardPath: dashboardData
                    ? `${tenantIdentifier ? `/${tenantIdentifier}` : ''}/dashboard/${campaign.id}/${
                          dashboardData.learnerId
                      }`
                    : undefined,
            })),
        [campaigns, dashboardData, tenantIdentifier],
    );
}
export type UseEnhancedCampaignsOpts = {
    campaigns: UserDashboardCampaign[];
    dashboardData: UserDashboardLoginResponse | undefined;
};
export type EnhancedUserDashboardCampaign = UserDashboardCampaign & { dashboardPath?: string };

import { useMemo } from 'react';
import { UserDashboardLoginResponse } from 'lib/api/apiTypes/UserDashboardLoginResponse';
import { CampaignAvailability } from '../../types/CampaignAvailability';
import { UserDashboardCampaign } from 'lib/api/apiTypes/UserDashboardCampaign';

export function useCampaigns({ availability, dashboardData }: UseCampaignsOpts): UserDashboardCampaign[] {
    return useMemo(
        () =>
            (availability === 'available' ? dashboardData?.currentCampaigns : dashboardData?.completedCampaigns) ?? [],
        [dashboardData, availability],
    );
}
export type UseCampaignsOpts = {
    availability: CampaignAvailability;
    dashboardData: UserDashboardLoginResponse | undefined;
};

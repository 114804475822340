import React, { FC } from 'react';
import { reactPlugin } from 'tracking/appInsights';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

export const AppInsightsProvider: FC<React.PropsWithChildren> = ({ children }) =>
    reactPlugin ? (
        <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
    ) : (
        <>{children}</>
    );

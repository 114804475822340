import { NetworkStatusActions } from 'redux-lib/actions/networkStatus';
import { createReducer } from 'typesafe-actions';

export type CircuitState = 'closed' | 'open' | 'halfOpen' | 'isolated';

export interface NetworkStatusState {
    circuitBreakerState: CircuitState;
    policies: Record<string, { retrying?: boolean; timedOut?: boolean; failed: boolean }>;
}

const defaultState: NetworkStatusState = {
    circuitBreakerState: 'closed',
    policies: {},
};

export const networkStatusReducer = createReducer<NetworkStatusState, NetworkStatusActions>(defaultState)
    .handleAction(NetworkStatusActions.circuitBreakerStateChange, (state, { payload: { state: circuitState } }) => ({
        ...state,
        circuitBreakerState: circuitState,
    }))
    .handleAction(NetworkStatusActions.policyCreated, (state, { payload: { policyId } }) => ({
        ...state,
        policies: { ...state.policies, [policyId]: { failed: false } },
    }))
    .handleAction(NetworkStatusActions.retryPolicyFailure, (state, { payload: { policyId } }) => ({
        ...state,
        policies: { ...state.policies, [policyId]: { failed: true } },
    }))
    .handleAction(NetworkStatusActions.retryPolicyRetry, (state, { payload: { policyId } }) => ({
        ...state,
        policies: { ...state.policies, [policyId]: { ...state.policies[policyId], retrying: true } },
    }))
    .handleAction(NetworkStatusActions.timeoutPolicyTimeout, (state, { payload: { policyId } }) => ({
        ...state,
        policies: { ...state.policies, [policyId]: { ...state.policies[policyId], timedOut: true } },
    }))
    .handleAction(NetworkStatusActions.policyDisposed, (state, { payload: { policyId } }) => ({
        ...state,
        policies: state.policies[policyId]?.failed === true ? state.policies : deleteProperty(state.policies, policyId),
    }));

export const deleteProperty = <T, K extends keyof T>(obj: T, id: K): Omit<T, K> => {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const { [id]: deleted, ...newState } = obj;
    return newState;
};

import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { delay } from 'lib/promises/delay';
import { CampaignResponse } from './apiTypes/CampaignResponse';
import { DashboardResponse } from './apiTypes/DashboardResponse';
import axios from 'lib/axios';
import { policyFactory } from 'lib/axios/policyFactory';

const fakeDashboardEndpoint = '/api/dashboardResponse.json';

export const fetchDashboardData = async (
    campaignId: string,
    userId: string,
    langCode: string,
    isReturningLearner: boolean,
): Promise<DashboardResponse> => {
    const apiUrl = import.meta.env.REACT_APP_OT_DASHBOARD_ENDPOINT
        ? `${import.meta.env.REACT_APP_OT_DASHBOARD_ENDPOINT}/${campaignId}/${userId}${
              langCode ? `?contentLanguage=${langCode}` : ''
          }`
        : undefined;

    const apiEndpoint = apiUrl || fakeDashboardEndpoint;

    let dashboardResponse: DashboardResponse | undefined;
    const startTime = performance.now();
    do {
        try {
            const response = await policyFactory.execute(
                (ctx) =>
                    axios.get<DashboardResponse>(apiEndpoint, {
                        validateStatus: (status) => [404, 200].includes(status),
                        signal: ctx.signal,
                    }),
                {
                    timeoutInterval: 10000,
                },
            );
            if (response.status === 200) {
                dashboardResponse = response.data;
            }
        } catch (e) {
            throw new ErrorWithData({ data: e, message: 'Dashboard API error' });
        }
        if (dashboardResponse && dashboardResponse.modules.length > 0) {
            const lessonsAfterInitialAssessment = dashboardResponse.modules.filter(
                (m) => m.name !== 'Initial Assessment',
            );
            if (!isReturningLearner || lessonsAfterInitialAssessment.length > 0) {
                return dashboardResponse;
            }
        }
        await delay(2000);
    } while (performance.now() - startTime < 40000);
    throw new ErrorWithData({
        data: dashboardResponse,
        message: 'Dashboard API response not found or contains no lessons',
    });
};

const fakeCampaignEndpoint = '/api/campaignResponse.json';

export const fetchCampaignData = async (campaignId: string, userId: string): Promise<CampaignResponse> => {
    const apiUrl = import.meta.env.REACT_APP_OT_CAMPAIGN_ENDPOINT
        ? `${import.meta.env.REACT_APP_OT_CAMPAIGN_ENDPOINT}/${campaignId}/${userId}`
        : undefined;

    const apiEndpoint = apiUrl || fakeCampaignEndpoint;

    try {
        const response = await policyFactory.execute(
            (ctx) =>
                axios.get<CampaignResponse>(apiEndpoint, {
                    signal: ctx.signal,
                    validateStatus: (status) => [404, 200].includes(status),
                }),
            {
                timeoutInterval: 10000,
            },
        );
        switch (response.status) {
            case 200:
                return response.data;
            case 404:
                return { campaignResponseNotFound: true, contentLanguage: 'en-GB' };
            default:
                throw new ErrorWithData({
                    data: response,
                    message: `Invalid response status code ${response.status}`,
                });
        }
    } catch (e) {
        throw new ErrorWithData({ data: e, message: 'Campaign API error' });
    }
};

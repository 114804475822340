import { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Hero from 'components/Hero';
import ModuleGrid from 'components/ModuleGrid';
import Container from 'components/styled/Container';
import BottomBar from 'components/BottomBar';
import AllCompletedMessage from 'components/AllCompletedMessage';
import TabButtons from 'components/TabButtons';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux-lib/RootState';
import { useParams } from 'react-router-dom';
import { ParamsActions } from 'redux-lib/actions/params';
import { TopBar } from 'components/TopBar';
import { SpinnerOverlay } from 'components/SpinnerOverlay';
import { progressSelector } from 'redux-lib/selectors/progressSelector';
import { DashboardState } from 'components/types/DashboardState';
import { useLessons } from './useLessons';

const Dashboard: FC<React.PropsWithChildren> = () => {
    const { campaignId, userId } = useParams();
    const {
        totalNumber,
        completedNumber,
        remainingNumber,
        progressPercentage,
        dashboardState,
        buttonUrl,
        certificateInfo,
    } = useSelector(progressSelector, shallowEqual);
    const lessons = useLessons();
    const languageChangeLoading = useSelector((state: RootState) => state.campaign.languageChangeLoading);
    const dispatch = useDispatch();
    const [showCompleted, setShowCompleted] = useState(false);

    useEffect(() => {
        if (campaignId) {
            dispatch(
                ParamsActions.setParams(
                    userId
                        ? { campaignId, userId, type: 'standardCampaignInitializationParameters' }
                        : { type: 'scormCampaignInitializationParameters', campaignId },
                ),
            );
        }
    }, [campaignId, userId, dispatch]);

    const initialized = useSelector((state: RootState) => state.initialization.initialized);

    if (!initialized) {
        return <SpinnerOverlay />;
    }

    return (
        <DashboardContainer>
            {languageChangeLoading && <SpinnerOverlay />}
            <TopBar />
            <Container>
                <Hero dashboardState={dashboardState} buttonUrl={buttonUrl} certificateInfo={certificateInfo} />
                {!hideLessonsStates.includes(dashboardState) && (
                    <>
                        <TabButtons
                            completedNumber={completedNumber}
                            remainingNumber={remainingNumber}
                            showCompleted={showCompleted}
                            setShowCompleted={setShowCompleted}
                        />
                        {remainingNumber === 0 && !showCompleted && <AllCompletedMessage />}
                        <ModuleGrid lessons={lessons} showCompleted={showCompleted} />
                    </>
                )}
            </Container>
            {!hideLessonsStates.includes(dashboardState) && (
                <BottomBar
                    totalNumber={totalNumber}
                    completedNumber={completedNumber}
                    progressPercentage={progressPercentage}
                    dashboardState={dashboardState}
                />
            )}
        </DashboardContainer>
    );
};

const hideLessonsStates: DashboardState[] = [
    'failedAllAttempts',
    'removedFromCampaign',
    'campaignResponseNotFound',
    'trainingExpired',
];

const DashboardContainer = styled.div`
    padding-bottom: 140px;
`;

export default Dashboard;

import styles from './error-styles.module.css';
import im from './images/im2_medium.jpg';

export const NotFound: React.FC<React.PropsWithChildren> = () => (
    <div className={styles.main}>
        <h1 className={styles.heading}>Oh no... We can&apos;t seem to find the page you&apos;re looking for.</h1>
        <p className={styles.body}>If you believe that this URL should work, please contact your support team.</p>
        <img className={styles.image} src={im} />
    </div>
);

// import React, { FC } from 'react';
// import { createSupportMailToURL404 } from 'lib/mailto/createSupportMailToURL404';

// export const NotFound: FC = () => {
//     const mailtoLink = createSupportMailToURL404();
//     return (
//         <div
//             style={{
//                 fontFamily: 'sans-serif',
//                 width: '100vw',
//                 height: '100vh',
//                 boxSizing: 'border-box',
//                 padding: '2em',
//                 position: 'fixed',
//                 top: 0,
//                 left: 0,
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 flexDirection: 'column',
//                 textAlign: 'center',
//                 backgroundColor: '#555',
//                 color: '#ddd',
//             }}
//         >
//             <div
//                 style={{
//                     width: '75%',
//                     maxWidth: '500px',
//                     boxSizing: 'border-box',
//                     padding: '2em',
//                     border: '3px solid #ddd',
//                     boxShadow: '4px 4px 20px black',
//                 }}
//             >
//                 <h1>Oh no... We can&apos;t seem to find the page you&apos;re looking for.</h1>
//                 <p>
//                     If you believe this URL should work, please consider sending us a report using the link below and
//                     we&apos;ll look into it.
//                 </p>
//                 <p>
//                     <a style={{ color: '#88f' }} href={mailtoLink}>
//                         Send Report
//                     </a>
//                 </p>
//             </div>
//         </div>
//     );
// };

import styled from 'styled-components/macro';
import { DestyledButton } from './DestyledButton';

const Button = styled(DestyledButton)`
    display: inline-flex;
    align-items: center;
    padding: 0.425em 1em;
    color: ${(props) => props.theme.button.color};
    background-color: ${(props) => props.theme.button.backgroundColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;

    & > svg {
        margin-right: 8px;
        fill: ${(props) => props.theme.button.iconColor};
        stroke: ${(props) => props.theme.button.iconColor};
    }

    @media screen and (max-width: 550px) {
        font-size: 14px;
    }
`;

export default Button;

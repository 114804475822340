import { TextsActions } from 'redux-lib/actions/texts';
import { createReducer } from 'typesafe-actions';

type TextsState = Record<string, string | string[] | null | undefined>;

const defaultState: TextsState = {};

export const textsReducer = createReducer<TextsState, TextsActions>(defaultState).handleAction(
    TextsActions.fetchResult,
    (state, { payload }) => {
        if (payload.type === 'Error') {
            return { ...state, error: payload.error };
        } else {
            return payload.value;
        }
    },
);

/**
 * Replaces specified placeholders in a string or each string within an array with their corresponding replacement values.
 *
 * @param {string | string[]} input - The input string or array of strings to process.
 * @param {Record<string, string>} replacements - An object mapping placeholders to their replacement values.
 * @return {string | string[]} The processed string or array of strings with placeholders replaced.
 */
export function replacePlaceholders(input: string | string[], replacements: Record<string, string>): string | string[] {
    const replaceFunction = (text: string): string => {
        let result = text;
        for (const [placeholder, replacement] of Object.entries(replacements)) {
            result = result.replace(new RegExp(`\\{\\{${placeholder}\\}\\}`, 'g'), replacement);
        }
        return result;
    };

    if (typeof input === 'string') {
        return replaceFunction(input);
    } else if (Array.isArray(input)) {
        return input.map(replaceFunction);
    }

    throw new Error('Input must be a string or an array of strings');
}

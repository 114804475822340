import React from 'react';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { createStore } from './redux-lib/createStore';
import { Provider as ReduxProvider } from 'react-redux';
import { browserHistory } from './browserHistory';
import { appInsights } from 'tracking/appInsights';
import { CatchBoundary } from './CatchBoundary';
import { AppInsightsProvider } from './AppInsightsProvider';
import { createRoot } from 'react-dom/client';

appInsights?.loadAppInsights();

const store = createStore();

const container = document.getElementById('root');
if (!container) {
    throw Error('there is no #root element');
}
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <AppInsightsProvider>
            <CatchBoundary>
                <ReduxProvider store={store}>
                    <App history={browserHistory} />
                </ReduxProvider>
            </CatchBoundary>
        </AppInsightsProvider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

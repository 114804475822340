import React, { FC } from 'react';
import { Catch } from './Catch';
import { ShowError } from 'components/error/ShowError';
import { reactPlugin } from 'tracking/appInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
const BasicCatchBoundary: FC<React.PropsWithChildren> = ({ children }) => {
    const [error, setError] = React.useState<any>(null);
    return <Catch onCatch={(e) => setError(e)}>{error ? <ShowError error={error} /> : <>{children}</>}</Catch>;
};

export const CatchBoundary = ({
    children,
}: {
    children: React.ReactElement<any, string | React.JSXElementConstructor<any>> & React.ReactNode;
}) =>
    reactPlugin ? (
        <AppInsightsErrorBoundary onError={(error) => <ShowError error={error} />} appInsights={reactPlugin}>
            {children}
        </AppInsightsErrorBoundary>
    ) : (
        <BasicCatchBoundary>{children}</BasicCatchBoundary>
    );

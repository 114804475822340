import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { TextsActions } from 'redux-lib/actions/texts';
import { InitializationActions } from '../../actions/initialization';
import { getTexts } from '../getTextsSaga';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { trackException } from 'tracking';
import { fetchUserDashboardData } from 'lib/api/fetchUserDashboardData';
import { UserDashboardActions } from 'redux-lib/actions/userDashboard';
import { UserDashboardLoginResponse } from 'lib/api/apiTypes/UserDashboardLoginResponse';
import { userDashboardTexts } from 'constants/texts';
import { fetchUserDashboardConfig } from 'lib/api/fetchUserDashboardConfig';
import { getThemeConfig } from './getThemeConfig';
import { UserDashboardLoginRequest } from 'lib/api/apiTypes/UserDashboardLoginRequest';
import { OtpTradeResponse } from 'lib/api/otpRequests';
import { UserDashboardConfig } from 'lib/api/apiTypes/UserDashboardConfig';
import { SagaDelegatedYieldReturnType } from 'lib/redux-saga/SagaDelegatedYieldReturnType';

export function* userDashboardInitializationSaga(organizationId: string): SagaIterator {
    let dashboardConfig: UserDashboardConfig;
    try {
        dashboardConfig = yield call(() => fetchUserDashboardConfig(organizationId));
        yield put(UserDashboardActions.setDashboardConfig(dashboardConfig));
    } catch (err) {
        console.warn('Dashboard config not available');
    }

    yield call(() => getStoredSession(dashboardConfig.signInOption, organizationId));

    yield call(() => getThemeConfig({ organizationId }));

    let textResults: Record<string, string | string[]> | undefined;
    try {
        textResults = yield call(getTexts, 'en-GB', userDashboardTexts);
    } catch (err) {
        trackException({
            exception: { exception: err as any },
            errorCode: 'FETCH_TEXTS_FAILED',
            addTrackingDetailsToException: true,
        });
        yield put(
            TextsActions.fetchResult({
                type: 'Error',
                error: prepareErrorForSerialization(err),
            }),
        );
    }

    if (!textResults) {
        const err = new ErrorWithData({ message: 'textResults is unexpectedly null', isMessagePublic: true });
        trackException({
            exception: { exception: err },
            errorCode: 'FETCH_TEXTS_IS_NULL',
            addTrackingDetailsToException: true,
        });

        yield put(InitializationActions.initializationFailure(prepareErrorForSerialization(err)));
        return;
    } else {
        yield put(TextsActions.fetchResult({ type: 'OK', value: textResults }));
    }

    yield put(InitializationActions.initializationSuccess());
}

export const userDataStorageKey = 'userDetails';
export const otpSessionStorageKey = 'otpSession';
export const orgIdStorageKey = 'organizationId';

function* getStoredSession(
    signInOption: UserDashboardConfig['signInOption'],
    organizationId: string,
): SagaDelegatedYieldReturnType<void> {
    if (signInOption === 'customId') {
        yield call(() => getStoredUserData(organizationId));
    }

    if (signInOption === 'email, otp') {
        yield call(() => getOtpStoredSession(organizationId));
    }
}

function* getStoredUserData(organizationId: string): SagaDelegatedYieldReturnType<void> {
    const storedUserData = sessionStorage.getItem(userDataStorageKey);
    if (storedUserData) {
        try {
            const userData: UserDashboardLoginRequest = JSON.parse(storedUserData);
            const res: UserDashboardLoginResponse | 'unauthorized' = yield call(() =>
                fetchUserDashboardData(userData, organizationId),
            );
            if (res === 'unauthorized') {
                clearStorage();
            } else {
                yield put(UserDashboardActions.setDashboardData(res));
            }
        } catch (e) {
            console.error(e);
        }
    }
}

function* getOtpStoredSession(organizationId: string): SagaDelegatedYieldReturnType<void> {
    const storedSession = sessionStorage.getItem(otpSessionStorageKey);
    if (storedSession) {
        try {
            const session: OtpTradeResponse = JSON.parse(storedSession);
            if (new Date() < new Date(session.expiry)) {
                const res: UserDashboardLoginResponse | 'unauthorized' = yield call(() =>
                    fetchUserDashboardData(undefined, organizationId, session.sessionId),
                );
                if (res === 'unauthorized') {
                    clearStorage();
                } else {
                    yield put(UserDashboardActions.setDashboardData(res));
                }
            } else {
                clearStorage();
            }
        } catch (e) {
            console.error(e);
        }
    }
}

function clearStorage() {
    sessionStorage.removeItem(userDataStorageKey);
    sessionStorage.removeItem(otpSessionStorageKey);
    sessionStorage.removeItem(orgIdStorageKey);
}

import { AxiosResponse } from 'axios';
import axiosWrapper from 'lib/axios';
import { lowerCaseKeys } from 'lib/util/lowerCaseKeys';
import { ThemeConfig } from 'outthink-frontend-training-theme';
import { ErrorWithData } from '../errors/ErrorWithData';
import { policyFactory } from 'lib/axios/policyFactory';
import axios from 'axios';

const envSuppliedEndpoint = import.meta.env.REACT_APP_THEME_CONFIG_ENDPOINT;

export interface ThemeConfigResponse {
    themeUrl: string | null;
    displayModuleDuration?: boolean;
}

export const fetchThemeConfigUrl = async ({
    campaignId,
    userId,
    organizationId,
}: {
    campaignId?: string;
    userId?: string;
    organizationId?: string;
}): Promise<ThemeConfigResponse | null> => {
    if (!envSuppliedEndpoint) {
        return null;
    }

    const apiEndpoint = `${envSuppliedEndpoint}`;

    const url = new URL(apiEndpoint);
    if (campaignId) {
        url.searchParams.set('campaignId', campaignId);
    }
    if (userId) {
        url.searchParams.set('learnerId', userId);
    }
    if (organizationId) {
        url.searchParams.set('organizationId', organizationId);
    }

    // const response: AxiosResponse<ThemeConfigResponse> = await Axios.get(url.href, {
    //     timeout: 10000,
    //     validateStatus: () => true,
    // });
    let response: AxiosResponse<ThemeConfigResponse>;
    try {
        response = await policyFactory.execute(
            ({ signal }) =>
                axiosWrapper.get(url.href, {
                    signal,
                }),
            { timeoutInterval: 10000 },
        );
    } catch (e) {
        throw new ErrorWithData({ data: e, message: 'API error' });
    }
    const data = response.data;
    const themeConfig =
        data?.themeUrl &&
        // should be fixed at backend (i.e. if there is no theme, then instead of
        // responding with 'https://.../default-theme-config.json', should actually
        // respond with `null`)
        !data.themeUrl.endsWith('/default-theme-config.json')
            ? data
            : { ...data, themeUrl: null };
    return themeConfig;
};

export interface ThemesAndLogos {
    training: ThemeConfig;
    trainingAssessment: ThemeConfig;
    dashboard: ThemeConfig;
    primaryLogo: string;
    secondaryLogo: string;
    highResLogo: string;
}

export const fetchThemeConfig = async (url: string): Promise<ThemesAndLogos | null> => {
    const response: AxiosResponse<ThemesAndLogos> = await policyFactory.execute((ctx) =>
        axios.get(url, {
            timeout: 10000,
            validateStatus: () => true,
            signal: ctx.signal,
        }),
    );
    if (response.status === 200) {
        return lowerCaseKeys(response.data);
    } else {
        throw new ErrorWithData({ data: response, message: 'API error' });
    }
};

import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { cutCorner } from './styled/css/cutCornerCss';

interface PopupProps {
    width?: string;
    className?: string;
    zIndex?: number;
}

export const Popup: FC<React.PropsWithChildren<React.PropsWithChildren<PopupProps>>> = ({
    width,
    className,
    zIndex,
    children,
}) => {
    return (
        <Overlay zIndex={zIndex}>
            <PopupContainer $width={width} className={className} tabIndex={0}>
                {children}
            </PopupContainer>
        </Overlay>
    );
};

const Overlay = styled.div<{ zIndex?: number }>`
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(0 0 0 / 50%);
    z-index: ${(props) => props.zIndex ?? 1999};
`;

const PopupContainer = styled.div<{ $width?: string }>`
    width: ${(props) => props.$width ?? 'auto'};
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 2px;
    padding: 20px 24px;
    position: relative;
    ${cutCorner('20px')}
`;

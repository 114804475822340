import { actionChannel, take, put, select, call } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { getType, ActionType, Action } from 'typesafe-actions';
import { TelemetryActions } from '../actions/telemetry';
import { prepareErrorForSerialization } from 'lib/errors/prepareErrorForSerialization';
import { retryWithBackoff } from 'lib/redux-saga/effects/retryWithBackoff';
import { getPostTelemetry } from '../../lib/telemetry/getPostTelemetry';
import { v4 as uuid } from 'uuid';
import { TelemetryOptions } from 'lib/telemetry/types/TelemetryOptions';
import { SagaIterator } from 'redux-saga';
import Result from 'lib/types/Result';
import { RootState } from 'redux-lib/RootState';
import { CampaignResponse, isOngoingCampaign, isCampaignNotFound } from 'lib/api/apiTypes/CampaignResponse';
import { telemetryEndpoint } from 'lib/telemetry/telemetryEndpoint';

export function* sendTelemetrySaga(): SagaIterator {
    const buffer = buffers.expanding<Action<any>>();
    const chan = yield actionChannel(getType(TelemetryActions.send), buffer);
    yield take(getType(TelemetryActions.startTelemetry));
    const campaign: CampaignResponse | undefined = yield select((state: RootState) => state.campaign.campaignResponse);
    const userId: string = yield select((state: RootState) => state.user.id || '');
    const currentAssignmentId = isOngoingCampaign(campaign) ? campaign.currentAssignment.assignmentId : null;
    const currentModuleId =
        isOngoingCampaign(campaign) && !isCampaignNotFound(campaign) ? campaign.currentAssignment.assignmentId : null;
    const sessionId = uuid();
    const assignmentId = currentAssignmentId || '';
    const moduleId = currentModuleId || '';
    const telemetryIsEnabled =
        telemetryEndpoint != null && [assignmentId, moduleId, userId, sessionId].every((x) => !!x);
    const telemetryOptions: TelemetryOptions = { assignmentId, moduleId, userId, sessionId };
    yield put(TelemetryActions.setOptions(telemetryOptions));
    const postTelemetry = getPostTelemetry(telemetryOptions);
    yield put(TelemetryActions.telemetryEnabledStatus(telemetryIsEnabled ? 'ok' : 'disabled'));
    for (;;) {
        yield put(TelemetryActions.queueStatus({ active: !buffer.isEmpty() }));

        const { payload }: ActionType<typeof TelemetryActions.send> = yield take(chan);
        yield put(TelemetryActions.queueStatus({ active: true }));

        if (typeof document !== 'undefined' && telemetryIsEnabled) {
            try {
                yield retryWithBackoff(() => postTelemetry(payload), {
                    retryEffectFac: (result) => {
                        if (telemetryEndpoint != null) {
                            return put(TelemetryActions.retryingSend(result));
                        } else {
                            return call(() => Promise.resolve()); //noop
                        }
                    },
                });
                yield put(TelemetryActions.sendResult(Result.success()));
            } catch (err: unknown) {
                if (telemetryEndpoint != null) {
                    yield put(TelemetryActions.sendResult(Result.error(prepareErrorForSerialization(err))));
                }

                break;
            }
        } else {
            console.warn('TELEMETRY DISABLED. Not sending event', payload);
        }
    }
    if (telemetryEndpoint != null) {
        yield put(TelemetryActions.sendQueueStopped());
    }
}

import { Result } from 'lib/types/Result';
import { ActionType, createAction } from 'typesafe-actions';

export const TextsActions = {
    fetchResult: createAction('texts/FETCH_RESULT')<Result<Record<string, string | string[] | null | undefined>>>(),
    addSubstitutions: createAction('texts/ADD_SUBSTITUTIONS')<Record<string, string | string[]>>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type TextsActions = ActionType<typeof TextsActions>;

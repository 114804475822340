import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { browserHistory } from '../browserHistory';

const instrumentationKey = import.meta.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY;

const reactPlugin = instrumentationKey ? new ReactPlugin() : undefined;
const appInsights = reactPlugin
    ? new ApplicationInsights({
          config: {
              instrumentationKey,
              extensions: [reactPlugin],
              extensionConfig: {
                  [reactPlugin.identifier]: { history: browserHistory },
              },
          },
      })
    : undefined;

export { reactPlugin, appInsights };

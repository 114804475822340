import { ActionType, createAction } from 'typesafe-actions';
import { LanguagesResponse } from 'lib/api/apiTypes/LanguagesResponse';
import { ThemesAndLogos } from 'lib/api/fetchThemeConfig';

export const InitializationActions = {
    languagesReceived: createAction('initialization/LANGUAGES_RECEIVED')<LanguagesResponse>(),
    themeConfigReceived: createAction('initialization/THEME_CONFIG_RECEIVED')<ThemesAndLogos>(),
    setHideModuleDuration: createAction('initialization/SET_HIDE_MODULE_DURATION')<boolean>(),
    initializationSuccess: createAction('initialization/SUCCESS')(),
    initializationFailure: createAction('initialization/FAILURE')<any>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InitializationActions = ActionType<typeof InitializationActions>;

import { SCORMFuncs } from './SCORMFuncs';
import Axios, { AxiosResponse } from 'axios';
import { SCORMParameters } from 'redux-lib/sagas/initializationSaga/SCORM/getSCORMParameters';
import { createPromiseResolver } from 'lib/promises/createPromiseResolver';
import { delay } from 'lib/promises/delay';
import { wrap } from 'comlink';
import { getAppInsightsTraceWrapper } from 'tracking/getAppInsightsTraceWrapper';
import { appInsights } from 'tracking/appInsights';

export const getSCORMFuncs = async (scormParameters: SCORMParameters): Promise<SCORMFuncs> => {
    const aiTracer = getAppInsightsTraceWrapper('dashboard getSCORMFuncs', appInsights, {
        scormSessionId: scormParameters.scormSessionId,
    });

    const scormOriginIsValid = await verifyScormOrigin(scormParameters.scormOrigin);
    if (!scormOriginIsValid) {
        console.log(`SCORM origin ${scormParameters.scormOrigin} is not valid | SCORM saga will not start`);
        throw Error(`SCORM origin ${scormParameters.scormOrigin} is not valid`);
    }
    console.log(`scormOriginIsValid: ${scormOriginIsValid} | SCORM origin is ${scormParameters.scormOrigin}`);
    const channel = new MessageChannel();
    const port = channel.port1;
    const comlinkConfirmed = waitForComlinkConfirm(port, scormParameters.scormSessionId);
    port.start();
    const connectMessage = {
        scormSessionId: scormParameters.scormSessionId,
        type: 'clientConnectData',
    };
    aiTracer?.trace('posting port to SCORM wrapper', { connectMessage, scormParameters });

    //console.log('posting port to SCORM wrapper');
    window.parent.postMessage(connectMessage, scormParameters.scormOrigin, [channel.port2]);
    const res = await Promise.any([comlinkConfirmed, delay(10000)]);
    if (res == null) {
        aiTracer?.trace('timeout attempting to connect to scorm-wrapper');
        throw Error('timeout attempting to connect to scorm-wrapper');
    }
    if (res?.data?._type !== 'ScormMessagePortReceived') {
        aiTracer?.trace('unexpected initialization message');
        throw Error('unexpected initialization message');
    }
    const comlinkProxy = wrap<SCORMFuncs>(port);
    return comlinkProxy;
};

async function waitForComlinkConfirm(
    port: MessagePort,
    scormSessionId: string,
): Promise<MessageEvent<{ _type: 'ScormMessagePortReceived' }>> {
    const aiTracer = getAppInsightsTraceWrapper('dashboard waitForComlinkConfirm', appInsights, { scormSessionId });

    const resolver = createPromiseResolver<MessageEvent<{ _type: 'ScormMessagePortReceived' }>>();
    const handler = (e: MessageEvent<any>): void => {
        aiTracer?.trace('received message', e.data);
        if (e.data._type === 'ScormMessagePortReceived') {
            aiTracer?.trace('message is ScormMessagePortReceived', e.data);
            port.removeEventListener('message', handler);
            resolver.resolve(e);
        }
    };
    port.addEventListener('message', handler);
    return resolver.promise;
}

const scormDomainValidationEndpoint = import.meta.env.REACT_APP_OT_SCORM_DOMAIN_VALIDATION_ENDPOINT;

async function verifyScormOrigin(scormOrigin: string): Promise<boolean> {
    const verificationURL = scormDomainValidationEndpoint + encodeURIComponent(scormOrigin);
    const response: AxiosResponse<{ isValidOrigin: boolean }> = await Axios.get(verificationURL, {
        timeout: 10000,
        validateStatus: () => true,
    });
    if (response.status === 200) {
        return response.data.isValidOrigin;
    } else {
        return false;
    }
}

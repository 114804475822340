import { takeEvery, cancel, all } from 'redux-saga/effects';
import { TelemetryActions } from '../actions/telemetry';
import { SagaIterator } from 'redux-saga';
import Result from 'lib/types/Result';

export function* telemetryWarningSaga(): SagaIterator {
    // eslint-disable-next-line
    const statusTeEffect = yield takeEvery(TelemetryActions.telemetryEnabledStatus, function* ({ payload }) {
        if (payload !== 'ok') {
            console.warn(`Telemetry status: ${payload}`);
        }
    });
    // eslint-disable-next-line
    const sendResultTeEffect = yield takeEvery(TelemetryActions.sendResult, function* ({ payload }) {
        if (!Result.isSuccess(payload)) {
            console.warn('An error occurred sending telemetry data');
        }
    });
    yield takeEvery(TelemetryActions.sendQueueStopped, function* () {
        console.warn('Send queue stopped');
        yield all([cancel(statusTeEffect), cancel(sendResultTeEffect)]);
    });
}

import { genTextsContentEntryInfo } from 'constants/texts';
import { ErrorWithData } from 'lib/errors/ErrorWithData';
import { ContentEntry } from './apiTypes/ContentEntry';
import { policyFactory } from 'lib/axios/policyFactory';
import axios from 'lib/axios';

const fakeTextsEndpoint = '/api/dashboardTextsResponse.json';
const fakeGenTextsEndpoint = '/api/genericTextsResponse.json';

export const fetchTexts = async (langCode: string, moduleId: string, entries: string[]): Promise<ContentEntry[]> => {
    const envSuppliedEndpoint = import.meta.env.REACT_APP_OT_CONTENT_API_ENDPOINT;
    let requestUrl: string | undefined;

    if (envSuppliedEndpoint) {
        const contentEndpoint = envSuppliedEndpoint;

        const url = new URL(contentEndpoint);
        url.searchParams.set('LanguageCode', langCode);
        url.searchParams.set('ModuleId', moduleId);
        url.searchParams.set('Entries', entries.join(','));

        requestUrl = url.toString();
    }

    if (!requestUrl) {
        requestUrl = moduleId === genTextsContentEntryInfo.moduleId ? fakeGenTextsEndpoint : fakeTextsEndpoint;
    }

    try {
        const url = requestUrl;
        const response = await policyFactory.execute(
            (ctx) =>
                axios.get<ContentEntry[]>(url, {
                    signal: ctx.signal,
                }),
            {
                timeoutInterval: 10000,
            },
        );
        return response.data;
    } catch (e) {
        throw new ErrorWithData({ data: e, message: 'Content API error' });
    }
};

import { ActionType, createAction } from 'typesafe-actions';
import { UserDashboardLoginResponse } from 'lib/api/apiTypes/UserDashboardLoginResponse';
import { UserDashboardConfig } from 'lib/api/apiTypes/UserDashboardConfig';

export const UserDashboardActions = {
    setDashboardData: createAction('userDashboard/SET_DASHBOARD_DATA')<UserDashboardLoginResponse>(),
    setDashboardConfig: createAction('userDashboard/SET_DASHBOARD_CONFIG')<UserDashboardConfig>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserDashboardActions = ActionType<typeof UserDashboardActions>;

import { policyFactory } from 'lib/axios/policyFactory';
import { ErrorWithData } from '../errors/ErrorWithData';
import { LanguagesResponse } from './apiTypes/LanguagesResponse';
import axios from 'lib/axios';

const envSuppliedEndpoint = import.meta.env.REACT_APP_OT_LANGUAGES_ENDPOINT;

export const fetchLanguages = async (): Promise<LanguagesResponse> => {
    const fakeApiEndpoint = '/api/languagesResponse.json';

    const apiEndpoint = envSuppliedEndpoint ? `${envSuppliedEndpoint}` : fakeApiEndpoint;

    try {
        const response = await policyFactory.execute(
            (ctx) =>
                axios.get<LanguagesResponse>(apiEndpoint, {
                    signal: ctx.signal,
                }),
            {
                timeoutInterval: 10000,
            },
        );
        return response.data;
    } catch (e) {
        throw new ErrorWithData({ data: e, message: 'Languages API error' });
    }
};

export const treatObjectKeys = (transformer: (v: string) => string): (<T>(obj: T) => T) => {
    const treatObjectKeysImpl = <T>(obj: T): T => {
        if (typeof obj !== 'object') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map((v) => treatObjectKeysImpl(v)) as any as T;
        }
        if (obj === null) {
            return null as any as T;
        }
        const entries = Object.entries(obj);
        const mappedEntries = entries.map(([k, v]) => [transformer(k), treatObjectKeysImpl(v)] as const);
        return Object.fromEntries(mappedEntries) as T;
    };
    return treatObjectKeysImpl;
};

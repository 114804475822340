import React, { FC, ReactNode, useMemo } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import { ReactComponent as NotStartedIllu } from 'assets/images/illu-notstarted.svg';
import { ReactComponent as InProgressIllu } from 'assets/images/illu-inprogress.svg';
import { ReactComponent as AssessmentIllu } from 'assets/images/illu-assessment.svg';
import { ReactComponent as CompleteIllu } from 'assets/images/illu-complete.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/icon-play.svg';
import { ReactComponent as AssessmentIcon } from 'assets/icons/icon-assessment.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';
import { DashboardState } from 'components/types/DashboardState';
import Button from './styled/Button';
import { useTexts } from 'lib/hooks/useTexts';
import { useIsRTLLayout } from 'lib/hooks/useIsRTLLayout';
import { CertificateInfo } from 'redux-lib/selectors/progressSelector';
import { Spinner } from './Spinner';

interface HeroProps {
    dashboardState: DashboardState;
    buttonUrl?: string | null;
    certificateInfo?: CertificateInfo;
}

const heroImgs: Record<DashboardState, ReactNode> = {
    firstVisit: <NotStartedIllu />,
    laterVisit: <InProgressIllu />,
    finalAssessment: <AssessmentIllu />,
    failedFinalAssessment: <AssessmentIllu />,
    failedAllAttempts: <AssessmentIllu />,
    removedFromCampaign: <AssessmentIllu />,
    trainingExpired: <AssessmentIllu />,
    campaignResponseNotFound: <AssessmentIllu />,
    allCompleted: <CompleteIllu />,
};

const buttonIcons: Record<DashboardState, ReactNode> = {
    firstVisit: <PlayIcon />,
    laterVisit: <PlayIcon />,
    finalAssessment: <AssessmentIcon />,
    failedFinalAssessment: <AssessmentIcon />,
    failedAllAttempts: null,
    removedFromCampaign: null,
    trainingExpired: null,
    campaignResponseNotFound: null,
    allCompleted: <EmailIcon />,
};

const useHeroText = (dashboardState: DashboardState) =>
    useTexts(['Greeting', 'Description', 'Button', 'Link'].map((role) => dashboardState + role));

const Spin = styled(Spinner)`
    margin-left: 1em;
`;

const Hero: FC<React.PropsWithChildren<HeroProps>> = ({ dashboardState, buttonUrl, certificateInfo }) => {
    const [greeting, description, buttonText, linkText] = useHeroText(dashboardState);
    const rtl = useIsRTLLayout();
    const theme = useTheme();

    const heroBgColors: Record<DashboardState, string> = useMemo(
        () => ({
            firstVisit: theme.colors.infoBg.color,
            laterVisit: theme.colors.warningBg.color,
            finalAssessment: theme.colors.dangerBg.color,
            failedFinalAssessment: theme.colors.dangerBg.color,
            failedAllAttempts: theme.colors.dangerBg.color,
            removedFromCampaign: theme.colors.dangerBg.color,
            trainingExpired: theme.colors.dangerBg.color,
            campaignResponseNotFound: theme.colors.dangerBg.color,
            allCompleted: theme.colors.successBg.color,
        }),
        [theme],
    );

    return (
        <HeroContainer bgColor={heroBgColors[dashboardState]}>
            <HeroLeft>
                <Title>{greeting}</Title>
                {newFunction(description)}
                {buttonUrl && (
                    <Button as="a" href={buttonUrl}>
                        {buttonIcons[dashboardState]}
                        {buttonText}
                    </Button>
                )}
                {certificateInfo && (certificateInfo.waitingForCertificate || certificateInfo.url != null) && (
                    <>
                        {certificateInfo.url ? (
                            <Button
                                as="a"
                                href={certificateInfo.url}
                                title={Array.isArray(linkText) ? linkText.join(' ') : linkText}
                            >
                                {linkText}
                            </Button>
                        ) : certificateInfo.waitingForCertificate ? (
                            <Button as="div" title={Array.isArray(linkText) ? linkText.join(' ') : linkText}>
                                {linkText} <Spin size="2em" color="#ffffff" />
                            </Button>
                        ) : null}
                    </>
                )}
            </HeroLeft>
            <HeroImg dashboardState={dashboardState} rtl={rtl}>
                {heroImgs[dashboardState]}
            </HeroImg>
        </HeroContainer>
    );
};

export const HeroContainer = styled.section<{ bgColor: string }>`
    display: flex;
    align-items: center;
    margin: 40px 0;
    background-color: ${(props) => props.bgColor};

    @media screen and (max-width: 500px) {
        margin: 20px 0;
    }
`;

export const HeroLeft = styled.div`
    flex: 0.6;
    padding: 40px 0 40px 40px;
    font-size: 20px;

    @media screen and (max-width: 800px) {
        padding-right: 40px;
        flex: 1;
    }

    @media screen and (max-width: 500px) {
        padding: 25px;
    }
`;

const heroImgAlign: Partial<Record<DashboardState, string>> = {
    laterVisit: 'flex-end',
    allCompleted: 'flex-start',
};

export const HeroImg = styled.div<{ dashboardState?: DashboardState; rtl: boolean }>`
    flex: 0.4;
    align-self: stretch;
    display: flex;
    align-items: ${(props) =>
        props.dashboardState && heroImgAlign[props.dashboardState] ? heroImgAlign[props.dashboardState] : 'center'};
    padding-right: ${(props) =>
        props.rtl && (props.dashboardState === 'allCompleted' || props.dashboardState === 'firstVisit') ? '40px' : 0};

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const Title = styled.h1`
    margin: 0 0 18px;
    font-size: 40px;
    ${(props) => props.theme.fontFamilyCSS.heading}

    @media screen and (max-width: 550px) {
        font-size: 32px;
        margin-bottom: 14px;
    }
`;

const Text = styled.div`
    margin-bottom: 28px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.6;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        margin-bottom: 20px;
    }
`;

export default Hero;
function newFunction(description: string | string[]) {
    return (Array.isArray(description) ? description : [description]).map((d, i) => <Text key={`text-${i}`}>{d}</Text>);
}

import { ActionType, createAction } from 'typesafe-actions';
import { CircuitState } from 'redux-lib/reducers/networkStatus';

export const NetworkStatusActions = {
    circuitBreakerStateChange: createAction('networkStatus/CIRCUIT_BREAKER_STATE_CHANGE')<{
        state: CircuitState;
    }>(),
    policyCreated: createAction('networkStatus/POLICY_CREATED')<{ policyId: string }>(),
    retryPolicyRetry: createAction('networkStatus/RETRY_POLICY_RETRY')<{
        policyId: string;
    }>(),
    // retryPolicySuccess: createAction('networkStatus/RETRY_POLICY_SUCCESS')<{
    //     policyId: string;
    // }>(),
    retryPolicyFailure: createAction('networkStatus/RETRY_POLICY_FAILURE')<{
        policyId: string;
    }>(),
    timeoutPolicyTimeout: createAction('networkStatus/TIMEOUT_POLICY_TIMEOUT')<{
        policyId: string;
    }>(),
    policyDisposed: createAction('networkStatus/POLICY_DISPOSED')<{
        policyId: string;
    }>(),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NetworkStatusActions = ActionType<typeof NetworkStatusActions>;

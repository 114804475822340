import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { DashboardResponse } from 'lib/api/apiTypes/DashboardResponse';
import { fetchDashboardData } from 'lib/api/fetchApiData';
import { SCORMParameters } from './initializationSaga/SCORM/getSCORMParameters';

export function* getDashboardData(
    campaignId: string,
    userId: string,
    langCode: string,
    isReturningLearner: boolean,
    scormParameters: SCORMParameters | undefined,
): SagaIterator {
    const dashboardDataTemp: DashboardResponse = yield call(() =>
        fetchDashboardData(campaignId, userId, langCode, isReturningLearner),
    );

    const dashboardData = scormParameters
        ? {
              ...dashboardDataTemp,
              modules: dashboardDataTemp.modules.map((lesson) => {
                  const url = new URL(lesson.url);
                  url.searchParams.set('scormOrigin', scormParameters.scormOrigin);
                  url.searchParams.set('scormSessionId', scormParameters.scormSessionId);
                  return { ...lesson, url: url.href };
              }),
          }
        : dashboardDataTemp;

    return dashboardData;
}
